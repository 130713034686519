export enum PageName {
  CLIENT = 'client',
  OPERATOR = 'operator',
  MANAGEMENT = 'management',
  INVOICE = 'invoice',
  CHARTS = 'charts',
  PERMISSION_DENIED = 'permission-denied',
  CLIENT_INVOICE = 'client-invoice',
  SATSURVEYS = 'sat-surveys',
  SATSUBSCIPTIONS = 'sat-subscriptions'
}
