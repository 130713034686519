





























































import { Component, Vue } from 'vue-property-decorator';
import { ManagementItem } from '@/interfaces/management';
import moment from 'moment';

export interface TotalData {
  key?: string;
  type?: string;
  receivedArea: number;
  unpublishedArea: number;
  rejectedArea: number;
  publishedArea: number;
  totalCost: number;
  avgDeliveryTime: number;
  minDeliveryTime: number;
  maxDeliveryTime: number;
  curationTimePerHa: number;
}

const totalColumns = [
  {
    title: 'Total',
    width: 150,
    dataIndex: 'type',
    key: 'type'
  },
  {
    width: 100
  },
  { title: '-', width: 100 },
  {
    title: '-',
    width: 100,
    dataIndex: 'receivedArea',
    key: 'receivedArea',
    scopedSlots: { customRender: 'area' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'unpublishedArea',
    key: 'unpublishedArea',
    scopedSlots: { customRender: 'area' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'rejectedArea',
    key: 'rejectedArea',
    scopedSlots: { customRender: 'area' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'publishedArea',
    key: 'publishedArea',
    scopedSlots: { customRender: 'area' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'totalCost',
    key: 'totalCost',
    scopedSlots: { customRender: 'cost' }
  },
  {
    title: '-',
    width: 150,
    key: 'deliveryTime',
    scopedSlots: { customRender: 'deliveryTime' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'curationTimePerHa',
    key: 'curationTimePerHa',
    scopedSlots: { customRender: 'time' }
  }
];

const columnsManagement = [
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    width: 150
  },
  {
    title: 'Product',
    dataIndex: 'analytic',
    key: 'analytic',
    width: 100,
    filters: [
      { text: 'sowing', value: 'sowing' },
      { text: 'weeds', value: 'weeds' }
    ],
    onFilter: (value, record) => record.analytic == value
  },
  {
    title: 'Objective',
    dataIndex: 'objective',
    key: 'objective',
    width: 100,
    filters: [
      { text: 'Demo', value: 'Demo' },
      { text: 'Commercial', value: 'Commercial' }
    ],
    onFilter: (value, record) => record.objective == value
  },
  {
    title: 'HA Received',
    dataIndex: 'receivedArea',
    key: 'receivedArea',
    scopedSlots: { customRender: 'area' },
    sorter: (a, b) => a.receivedArea - b.receivedArea,
    width: 100
  },
  {
    title: 'HA Unpublished',
    dataIndex: 'unpublishedArea',
    key: 'unpublishedArea',
    scopedSlots: { customRender: 'area' },
    sorter: (a, b) => a.unpublishedArea - b.unpublishedArea,
    width: 100
  },
  {
    title: 'HA Rejected',
    dataIndex: 'rejectedArea',
    key: 'rejectedArea',
    scopedSlots: { customRender: 'area' },
    sorter: (a, b) => a.rejectedArea - b.rejectedArea,
    width: 100
  },
  {
    title: 'HA Published',
    dataIndex: 'publishedArea',
    key: 'publishedArea',
    scopedSlots: { customRender: 'area' },
    sorter: (a, b) => a.publishedArea - b.publishedArea,
    width: 100
  },
  {
    title: 'Total cost',
    dataIndex: 'totalCost',
    key: 'totalCost',
    scopedSlots: { customRender: 'cost' },
    sorter: (a, b) => a.totalCost - b.totalCost,
    width: 100
  },
  {
    title: 'Delivery time',
    key: 'deliveryTime',
    scopedSlots: { customRender: 'deliveryTime' },
    sorter: (a, b) => a.avgDeliveryTime - b.avgDeliveryTime,
    width: 150
  },
  {
    title: 'Curation time / HA',
    dataIndex: 'curationTimePerHa',
    key: 'curationTimePerHa',
    scopedSlots: { customRender: 'time' },
    sorter: (a, b) => a.curationTimePerHa - b.curationTimePerHa,
    width: 100
  }
];

@Component({})
export default class ManagementTable extends Vue {
  private columnsManagement = columnsManagement;
  private totalColumns = totalColumns;
  defaultRange = [moment().subtract(7, 'days').utcOffset(0).startOf('day'), moment().utcOffset(0).endOf('day')];

  get dataSource(): ManagementItem[] {
    return this.$store.state.management.filteredData;
  }

  mounted(): void {
    const [from, to] = this.defaultRange;
    this.$store.dispatch('management/loadData', { from: from.toISOString(), to: to.toISOString() });
  }

  onRangeChange([from, to]: [moment.Moment, moment.Moment]): void {
    if (from && to) {
      this.$store.dispatch('management/loadData', {
        from: from.utcOffset(0).startOf('day').toISOString(),
        to: to.utcOffset(0).endOf('day').toISOString()
      });
    } else {
      this.$store.dispatch('management/loadData');
    }
  }

  getTotalData(currentPageData: ManagementItem[]): TotalData {
    return currentPageData.reduce(
      (acc: TotalData, item: ManagementItem) => {
        return {
          receivedArea: acc.receivedArea + item.receivedArea,
          unpublishedArea: acc.unpublishedArea + item.unpublishedArea,
          rejectedArea: acc.rejectedArea + item.rejectedArea,
          publishedArea: acc.publishedArea + item.publishedArea,
          totalCost: acc.totalCost + item.totalCost,
          avgDeliveryTime: acc.avgDeliveryTime + item.avgDeliveryTime,
          minDeliveryTime: acc.minDeliveryTime + item.minDeliveryTime,
          maxDeliveryTime: acc.maxDeliveryTime + item.maxDeliveryTime,
          curationTimePerHa: acc.curationTimePerHa + item.curationTimePerHa
        } as TotalData;
      },
      {
        receivedArea: 0,
        unpublishedArea: 0,
        rejectedArea: 0,
        publishedArea: 0,
        totalCost: 0,
        avgDeliveryTime: 0,
        minDeliveryTime: 0,
        maxDeliveryTime: 0,
        curationTimePerHa: 0
      } as TotalData
    );
  }

  getTotalDataList(currentPageData: ManagementItem[]): TotalData[] {
    const totalLength = currentPageData.length;
    const totalData = this.getTotalData(currentPageData);
    totalData.key = 'totalData';
    totalData.type = 'Total';

    const averageData = {
      key: 'averageData',
      type: 'Average',
      receivedArea: totalData.receivedArea / totalLength,
      unpublishedArea: totalData.unpublishedArea / totalLength,
      rejectedArea: totalData.rejectedArea / totalLength,
      publishedArea: totalData.publishedArea / totalLength,
      totalCost: totalData.totalCost / totalLength,
      avgDeliveryTime: totalData.avgDeliveryTime / totalLength,
      minDeliveryTime: totalData.minDeliveryTime / totalLength,
      maxDeliveryTime: totalData.maxDeliveryTime / totalLength,
      curationTimePerHa: totalData.curationTimePerHa / totalLength
    } as TotalData;

    return [averageData, totalData];
  }

  onSearch(value: string): void {
    this.$store.dispatch('management/filterData', { search: value });
  }

  get tableScrollOpt(): { y: number } {
    const headerHeight = 180;
    const footerHeight = 155;
    return { y: window.innerHeight - headerHeight - footerHeight };
  }
}
