import { Filter } from '@/interfaces/filter';
import { Cluster } from '@/interfaces/cluster';
import { Survey } from '@/interfaces/survey';

export enum ClientSurveyStatus {
  Processing = 'Processing',
  QualityReview = 'Quality Review',
  Rejected = 'Rejected',
  Published = 'Published'
}

export type ClientSurveyStatusType =
  | ClientSurveyStatus.Processing
  | ClientSurveyStatus.QualityReview
  | ClientSurveyStatus.Rejected
  | ClientSurveyStatus.Published;

export interface ClientSurvey extends Omit<Survey, 'status'> {
  status: ClientSurveyStatusType;
}

export enum ClientClusterStatus {
  Uploading = 'Uploading',
  Stitching = 'Stitching',
  OrthoReview = 'Ortho Review',
  Processing = 'Processing',
  QualityReview = 'Quality Review',
  Published = 'Published',
  Rejected = 'Rejected'
}

export type ClientClusterStatusType =
  | ClientClusterStatus.Uploading
  | ClientClusterStatus.Stitching
  | ClientClusterStatus.OrthoReview
  | ClientClusterStatus.Processing
  | ClientClusterStatus.QualityReview
  | ClientClusterStatus.Published
  | ClientClusterStatus.Rejected;

export type ClientClusterStatusPhaseType =
  | ClientClusterStatus.Uploading
  | ClientClusterStatus.Stitching
  | ClientClusterStatus.OrthoReview;

export interface ClientCluster extends Omit<Cluster, 'status' | 'surveys'> {
  status: ClientClusterStatusType;
  surveys: ClientSurvey[];
}

export interface ClientState {
  clusters: ClientCluster[];
  filteredClusters: ClientCluster[];
  filter: Filter;
}
