export enum ProgressStatus {
  success = 'success',
  exception = 'exception',
  normal = 'normal',
  active = 'active'
}

export type ProgressStatusType =
  | ProgressStatus.success
  | ProgressStatus.exception
  | ProgressStatus.normal
  | ProgressStatus.active;
