





































































import { Component, Vue } from 'vue-property-decorator';
import { Invoice } from '@/interfaces/invoice';
import moment from 'moment';
import JsonCSV from 'vue-json-csv';
import { m2ToHa } from '@/filters/area';
import { ProductType, ProductTypeLabel } from '@/enums/output';
import { TableFilter, TablePagination } from '@/interfaces/table';
import { filterTableList } from '@/services/utils';

export interface TotalData {
  key?: string;
  type?: string;
  deliveredArea: number;
  curationTimePerHa: number;
  totalCost: number;
  totalCurationTime: number;
  totalListPrice: number;
}

const totalColumns = [
  {
    title: 'Total',
    width: 150,
    dataIndex: 'type',
    key: 'type'
  },
  {
    width: 100
  },
  { title: '-', width: 100 },
  {
    title: '-',
    width: 100,
    dataIndex: 'deliveredArea',
    key: 'deliveredArea',
    scopedSlots: { customRender: 'area' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'totalCost',
    key: 'totalCost',
    scopedSlots: { customRender: 'cost' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'totalListPrice',
    key: 'totalListPrice',
    scopedSlots: { customRender: 'price' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'curationTimePerHa',
    key: 'curationTimePerHa',
    scopedSlots: { customRender: 'time' }
  }
];

const columnsInvoice = [
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    width: 150
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    width: 100,
    scopedSlots: { customRender: 'product' },
    filters: [
      { text: ProductTypeLabel.sowing, value: ProductType.PlantingGaps },
      { text: ProductTypeLabel.weeds, value: ProductType.Weeds },
      { text: ProductTypeLabel.phl, value: ProductType.PHL },
      { text: ProductTypeLabel.weeds_classes, value: ProductType.WeedsClasses },
      { text: ProductTypeLabel['ortho pix4d'], value: ProductType.OrthoPix4d },
      { text: ProductTypeLabel['ortho metashape'], value: ProductType.OrthoMetashape }
    ],
    onFilter: (value, record) => record.product === value
  },
  {
    title: 'Objective',
    dataIndex: 'objective',
    key: 'objective',
    width: 100,
    filters: [
      { text: 'Demo', value: 'Demo' },
      { text: 'Commercial', value: 'Commercial' },
      { text: 'R&D', value: 'R&D' },
      { text: 'Other', value: 'Other' }
    ],
    onFilter: (value, record) => record.objective === value
  },
  {
    title: 'HA Invoiced',
    dataIndex: 'deliveredArea',
    key: 'deliveredArea',
    scopedSlots: { customRender: 'area' },
    sorter: (a, b) => a.deliveredArea - b.deliveredArea,
    width: 100
  },
  {
    title: 'Total cost',
    dataIndex: 'totalCost',
    key: 'totalCost',
    scopedSlots: { customRender: 'cost' },
    sorter: (a, b) => a.totalCost - b.totalCost,
    width: 100
  },
  {
    title: 'Total List Price',
    dataIndex: 'totalListPrice',
    key: 'totalListPrice',
    scopedSlots: { customRender: 'price' },
    sorter: (a, b) => a.totalListPrice - b.totalListPrice,
    width: 100
  },
  {
    title: 'Curation time / HA',
    dataIndex: 'curationTimePerHa',
    key: 'curationTimePerHa',
    scopedSlots: { customRender: 'time' },
    sorter: (a, b) => a.curationTimePerHa - b.curationTimePerHa,
    width: 100
  }
];

@Component({
  components: {
    downloadCsv: JsonCSV
  }
})
export default class InvoiceTable extends Vue {
  fileName = `${new Date().getTime()}.csv`;
  tableFilter: TableFilter = {}; // filters chosen on table, uses only for export data what user currently see

  private columnsInvoice = columnsInvoice;
  private totalColumns = totalColumns;
  defaultRange = [moment().subtract(7, 'days').utcOffset(0).startOf('day'), moment().utcOffset(0).endOf('day')];

  labels = {
    client: 'Client',
    objective: 'Objective',
    product: 'Product',
    deliveredArea: 'Area',
    totalCost: 'Cost',
    curationTimePerHa: 'AvgCurationTime',
    totalListPrice: 'TotalListPrice'
  };
  exportingFields = [
    'client',
    'objective',
    'product',
    'deliveredArea',
    'totalCost',
    'curationTimePerHa',
    'totalListPrice'
  ];

  get dataSource(): Invoice[] {
    return this.$store.state.invoice.filteredData;
  }

  mounted(): void {
    const [from, to] = this.defaultRange;
    this.$store.dispatch('invoice/loadData', { from: from.toISOString(), to: to.toISOString() });
  }

  get exportData(): Invoice[] {
    return filterTableList(this.dataSource, this.tableFilter).map((invoice: Invoice) => {
      return {
        ...invoice,
        product: this.getProductTypeLabel(invoice.product as ProductType),
        curationTimePerHa: Math.round((invoice.curationTimePerHa / 60000) * 100) / 100,
        deliveredArea: m2ToHa(invoice.deliveredArea),
        totalCost: Math.round(invoice.totalCost * 100) / 100
      };
    });
  }

  onRangeChange([from, to]: [moment.Moment, moment.Moment]): void {
    if (from && to) {
      this.$store.dispatch('invoice/loadData', {
        from: from.utcOffset(0).startOf('day').toISOString(),
        to: to.utcOffset(0).endOf('day').toISOString()
      });
    } else {
      this.$store.dispatch('invoice/loadData');
    }
  }

  getTotalData(currentPageData: Invoice[]): TotalData {
    return currentPageData.reduce(
      (acc: TotalData, item: Invoice) => {
        return {
          deliveredArea: acc.deliveredArea + item.deliveredArea,
          curationTimePerHa: acc.curationTimePerHa + item.curationTimePerHa,
          totalCost: acc.totalCost + item.totalCost,
          totalCurationTime: acc.totalCurationTime + item.totalCurationTime,
          totalListPrice: acc.totalListPrice + item.totalListPrice
        } as TotalData;
      },
      {
        deliveredArea: 0,
        curationTimePerHa: 0,
        totalCost: 0,
        totalCurationTime: 0,
        totalListPrice: 0
      } as TotalData
    );
  }

  getTotalDataList(currentPageData: Invoice[]): TotalData[] {
    const totalLength = currentPageData.length;
    const totalData = this.getTotalData(currentPageData);
    totalData.key = 'totalData';
    totalData.type = 'Total';

    const averageData = {
      key: 'averageData',
      type: 'Average',
      deliveredArea: totalData.deliveredArea / totalLength,
      curationTimePerHa: totalData.curationTimePerHa / totalLength,
      totalCost: totalData.totalCost / totalLength,
      totalCurationTime: totalData.totalCurationTime / totalLength,
      totalListPrice: totalData.totalListPrice / totalLength
    } as TotalData;

    return [averageData, totalData];
  }

  onSearch(value: string): void {
    this.$store.dispatch('invoice/filterData', { search: value });
  }

  get tableScrollOpt(): { y: number } {
    const headerHeight = 180;
    const footerHeight = 108;
    return { y: window.innerHeight - headerHeight - footerHeight };
  }

  getProductTypeLabel(productType: ProductType): string {
    return ProductTypeLabel[productType] || productType;
  }

  onTableChange(pagination: TablePagination, filters: TableFilter): void {
    // function has 3 param as sorter: TableSorter
    this.tableFilter = filters;
  }
}
