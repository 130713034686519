import { Role } from '@/enums/role';
import { RootState } from '@/interfaces/root-state';
export function isInDateRange(from: string, to: string, date: string): boolean {
  if (!date) {
    return false;
  }

  const dateTimeValue = new Date(date).getTime();
  // empty range show all the data if invoiced set to true
  const fromValue = from ? new Date(from).getTime() : new Date(0).getTime();
  const toValue = to ? new Date(to).getTime() : new Date().getTime();

  return dateTimeValue >= fromValue && dateTimeValue <= toValue;
}

export function isMatchList(matchList: string[], search: string): boolean {
  return matchList.some((value) => {
    return value.toLowerCase().includes(search.toLowerCase());
  });
}

export function parseSearch(search: string): string[] {
  return search.split(' ');
}

export function isAdmin(state: RootState): boolean {
  const {
    userInfo: { Roles: roles }
  } = state;

  return roles.includes(Role.admin);
}

export function isDashboardRole(state: RootState): boolean {
  const {
    userInfo: { Roles: roles }
  } = state;

  return roles.includes(Role.dashboard);
}

export function hasRoles(state: RootState): boolean {
  const { userInfo } = state;

  if (!userInfo) {
    return false;
  }

  return !!userInfo.Roles;
}

export function hasUserOrg(state: RootState): boolean {
  const { orgNames } = state;

  if (orgNames && orgNames.length) {
    return true;
  }

  return false;
}
