









































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { PageName } from '@/enums/page-name';
import { Role } from '@/enums/role';
import { hasUserOrg } from '@/store/utils';

@Component
export default class Header extends Vue {
  pageName = PageName;
  role = Role;
  defaultSelectedKeys = [];
  selectedKeys: string[] = [];

  mounted(): void {
    this.selectedKeys = [this.$route.name];
  }

  @Watch('$route')
  onRouteChange(to: Route): void {
    this.selectedKeys = [to.name];
  }

  get hasRoleAccess(): boolean {
    return (
      this.$store.state.userInfo.Roles &&
      (this.$store.state.userInfo.Roles.indexOf(Role.admin) >= 0 ||
        this.$store.state.userInfo.Roles.indexOf(Role.dashboard) >= 0)
    );
  }

  get isUserWithOrgs(): boolean {
    const { state } = this.$store;
    return hasUserOrg(state);
  }
}
