





















































































import { Component, Vue } from 'vue-property-decorator';
import { ClientInvoice, ClientNotice, DetailClientInvoice } from '@/interfaces/client-invoice';
import moment from 'moment';
import JsonCSV from 'vue-json-csv';
import { m2ToHa } from '@/filters/area';
import { CurrencyType } from '@/enums/currency';
import { ProductType, ProductTypeLabel } from '@/enums/output';
import { TableFilter, TablePagination } from '@/interfaces/table';
import { filterTableList } from '@/services/utils';
import { currencyValue } from '@/filters/currency';

export interface TotalClientInvoice {
  key?: string;
  type?: string;
  totalInvoicedArea: number;
  totalListPrice: number;
  totalDiscount: number;
  finalTotalPrice: number;
}

export interface Labels {
  [key: string]: string;
}

const totalColumns = [
  {
    title: 'Total',
    width: 150,
    dataIndex: 'type',
    key: 'type'
  },
  {
    width: 100
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'totalInvoicedArea',
    key: 'totalInvoicedArea',
    scopedSlots: { customRender: 'area' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'totalListPrice',
    key: 'totalListPrice',
    scopedSlots: { customRender: 'price' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'totalDiscount',
    key: 'totalDiscount',
    scopedSlots: { customRender: 'price' }
  },
  {
    title: '-',
    width: 100,
    dataIndex: 'finalTotalPrice',
    key: 'finalTotalPrice',
    scopedSlots: { customRender: 'price' }
  }
];

const columnsClientInvoice = [
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    width: 150
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    scopedSlots: { customRender: 'product' },
    width: 100,
    filters: [
      { text: ProductTypeLabel.sowing, value: ProductType.PlantingGaps },
      { text: ProductTypeLabel.weeds, value: ProductType.Weeds },
      { text: ProductTypeLabel.phl, value: ProductType.PHL },
      { text: ProductTypeLabel.weeds_classes, value: ProductType.WeedsClasses },
      { text: ProductTypeLabel['ortho pix4d'], value: ProductType.OrthoPix4d },
      { text: ProductTypeLabel['ortho metashape'], value: ProductType.OrthoMetashape }
    ],
    onFilter: (value, record) => record.product === value
  },
  {
    title: 'HA Invoiced',
    dataIndex: 'invoicedArea',
    key: 'invoicedArea',
    scopedSlots: { customRender: 'area' },
    sorter: (a, b) => a.invoicedArea - b.invoicedArea,
    width: 100
  },
  {
    title: 'Total List Price',
    dataIndex: 'totalListPrice',
    key: 'totalListPrice',
    scopedSlots: { customRender: 'price' },
    sorter: (a, b) => a.totalListPrice - b.totalListPrice,
    width: 100
  },
  {
    title: 'Total Discount',
    dataIndex: 'totalDiscount',
    key: 'totalDiscount',
    scopedSlots: { customRender: 'price' },
    sorter: (a, b) => a.totalDiscount - b.totalDiscount,
    width: 100
  },
  {
    title: 'Final Total Price',
    dataIndex: 'finalTotalPrice',
    key: 'finalTotalPrice',
    scopedSlots: { customRender: 'price' },
    sorter: (a, b) => a.finalTotalPrice - b.finalTotalPrice,
    width: 100
  }
];

@Component({
  components: {
    downloadCsv: JsonCSV
  }
})
export default class ClientInvoiceSummary extends Vue {
  tableFilter: TableFilter = {}; // filters chosen on table, uses only for export data what user currently see

  private columnsClientInvoice = columnsClientInvoice;
  private totalColumns = totalColumns;
  defaultRange = [moment().subtract(7, 'days').utcOffset(0).startOf('day'), moment().utcOffset(0).endOf('day')];

  // TODO: implement currency selection, move to store if other components need it
  selectedCurrency = CurrencyType.BRL;

  invoiceExportFields = ['client', 'product', 'invoicedArea', 'totalListPrice', 'totalDiscount', 'finalTotalPrice'];

  detailInvoiceExportFields = [
    'surveyId',
    'parcelName',
    'product',
    'farmName',
    'areaHa',
    'status',
    'uploadingEnd',
    'invoiceTime'
  ];

  get dataSource(): ClientInvoice[] {
    return this.$store.state.clientInvoice.filteredData;
  }

  get invoiceLabels(): Labels {
    return {
      client: 'Client',
      product: 'Product',
      invoicedArea: 'Area',
      totalListPrice: `TotalListPrice (${this.selectedCurrency})`,
      totalDiscount: `TotalDiscount (${this.selectedCurrency})`,
      finalTotalPrice: `FinalTotalPrice (${this.selectedCurrency})`
    };
  }

  get detailInvoiceExportData(): DetailClientInvoice[] {
    return this.$store.state.clientInvoice.filteredDetailData.map((detailInvoice) => ({
      ...detailInvoice,

      uploadingEnd: detailInvoice.uploadingEnd
        ? new Date(detailInvoice.uploadingEnd).toISOString().substr(0, 10)
        : null,
      invoiceTime: detailInvoice.invoiceTime ? new Date(detailInvoice.invoiceTime).toISOString().substr(0, 10) : null,
      product: this.getProductTypeLabel(detailInvoice.product)
    }));
  }

  get detailInvoiceLabels(): Labels {
    return {
      product: 'Product',
      parcelName: 'ParcelName',
      farmName: 'FarmName',
      surveyId: 'SurveyId',
      areaHa: 'Area',
      status: 'Status',
      uploadingEnd: 'DateUploaded',
      invoiceTime: 'DateInvoiced'
    };
  }

  get invoiceExportData(): ClientInvoice[] {
    return filterTableList(this.dataSource, this.tableFilter).map((clientInvoice) => {
      return {
        ...clientInvoice,
        product: this.getProductTypeLabel(clientInvoice.product as ProductType),
        invoicedArea: m2ToHa(clientInvoice.invoicedArea),
        totalListPrice: currencyValue(clientInvoice.totalListPrice, this.selectedCurrency),
        totalDiscount: currencyValue(clientInvoice.totalDiscount, this.selectedCurrency),
        finalTotalPrice: currencyValue(clientInvoice.finalTotalPrice, this.selectedCurrency)
      };
    });
  }

  get tableScrollOpt(): { y: number } {
    const headerHeight = 180;
    const footerHeight = 108;
    return { y: window.innerHeight - headerHeight - footerHeight };
  }

  get clientNotices(): ClientNotice[] {
    return this.$store.state.clientInvoice.notices;
  }

  mounted(): void {
    const [from, to] = this.defaultRange;

    this.$store.dispatch('clientInvoice/loadData', { from: from.toISOString(), to: to.toISOString() });

    this.$store.dispatch('clientInvoice/loadDetailData', { from: from.toISOString(), to: to.toISOString() });

    this.$store.dispatch('clientInvoice/loadNoticesData', { from: from.toISOString(), to: to.toISOString() });
  }

  onRangeChange([from, to]: [moment.Moment, moment.Moment]): void {
    if (from && to) {
      this.$store.dispatch('clientInvoice/loadData', {
        from: from.utcOffset(0).startOf('day').toISOString(),
        to: to.utcOffset(0).endOf('day').toISOString()
      });

      this.$store.dispatch('clientInvoice/loadDetailData', {
        from: from.utcOffset(0).startOf('day').toISOString(),
        to: to.utcOffset(0).endOf('day').toISOString()
      });

      this.$store.dispatch('clientInvoice/loadNoticesData', {
        from: from.utcOffset(0).startOf('day').toISOString(),
        to: to.utcOffset(0).endOf('day').toISOString()
      });
    } else {
      this.$store.dispatch('clientInvoice/loadData');
      this.$store.dispatch('clientInvoice/loadDetailData');
      this.$store.dispatch('clientInvoice/loadNoticesData');
    }
  }

  getTotalData(currentPageData: ClientInvoice[]): TotalClientInvoice {
    return currentPageData.reduce(
      (acc: TotalClientInvoice, item: ClientInvoice) => {
        return {
          totalInvoicedArea: acc.totalInvoicedArea + item.invoicedArea,
          totalListPrice: acc.totalListPrice + item.totalListPrice,
          totalDiscount: acc.totalDiscount + item.totalDiscount,
          finalTotalPrice: acc.finalTotalPrice + item.finalTotalPrice
        } as TotalClientInvoice;
      },
      {
        totalInvoicedArea: 0,
        totalListPrice: 0,
        totalDiscount: 0,
        finalTotalPrice: 0
      } as TotalClientInvoice
    );
  }

  getTotalDataList(currentPageData: ClientInvoice[]): TotalClientInvoice[] {
    const totalData = this.getTotalData(currentPageData);
    totalData.key = 'totalData';
    totalData.type = 'Total';

    return [totalData];
  }

  onSearch(value: string): void {
    this.$store.dispatch('clientInvoice/filterData', { search: value });
  }

  getProductTypeLabel(productType: ProductType): string {
    return ProductTypeLabel[productType] || productType;
  }

  onTableChange(pagination: TablePagination, filters: TableFilter): void {
    // function has 3 param as sorter: TableSorter
    this.tableFilter = filters;
  }

  getCSVFileName(prefix: string): string {
    return `${prefix}_${new Date().getTime()}.csv`;
  }

  getNoticeRangeLabel(notice: ClientNotice): string {
    const from = notice.from ? notice.from.slice(0, 10) : '';
    const to = notice.to ? notice.to.slice(0, 10) : '';

    return `${from} - ${to}`;
  }
}
