import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import App from './App.vue';
import router from './router';
import store from './store';
import dateFilter from '@/filters/date';
import timeFilter from '@/filters/time';
import processingTypeFilter from '@/filters/processingType';
import { m2ToHa } from '@/filters/area';
import roundFilter from '@/filters/round';
import { currency } from '@/filters/currency';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import {
  Button,
  Spin,
  Table,
  Menu,
  Icon,
  Input,
  Collapse,
  Progress,
  Select,
  Tag,
  DatePicker,
  Slider,
  Steps,
  Popover,
  InputNumber,
  Dropdown,
  Checkbox,
  Row,
  Col,
  Modal,
  Alert
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import moment from 'moment-timezone';

Vue.config.productionTip = false;
Vue.use(VueClipboard);

Vue.use(Button);
Vue.use(Spin);
Vue.use(Table);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Collapse);
Vue.use(Progress);
Vue.use(Select);
Vue.use(Tag);
Vue.use(DatePicker);
Vue.use(Slider);
Vue.use(Steps);
Vue.use(Popover);
Vue.use(InputNumber);
Vue.use(Dropdown);
Vue.use(Checkbox);
Vue.use(Row);
Vue.use(Col);
Vue.use(Modal);
Vue.use(Alert);

Vue.filter('formatDate', dateFilter);
Vue.filter('timeFilter', timeFilter);
Vue.filter('processingTypeFilter', processingTypeFilter);
Vue.filter('m2ToHa', m2ToHa);
Vue.filter('round', roundFilter);
Vue.filter('currency', currency);

const injectUserSnap = () => {
  (window as any).onUsersnapCXLoad = function (api) {
    api.init({ button: null });
    (window as any).userSnap = api;
    init();
  };
  const script = document.createElement('script') as any;
  script.defer = 1;
  script.nonce = 'usersnap';
  script.onerror = function () {
    init();
  };
  script.src =
    'https://widget.usersnap.com/global/load/348480da-d057-4813-a9ab-d1221d33e825?onload=onUsersnapCXLoad&n=usersnap';
  document.getElementsByTagName('head')[0].appendChild(script);
};
injectUserSnap();

moment.tz.setDefault('UTC');

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: ['Request failed with status code 403', 'Request failed with status code 404'],
    logErrors: true,
    environment: process.env.VUE_APP_ENVIRONMENT,
    debug: process.env.VUE_APP_ENVIRONMENT !== 'prod',
    release: process.env.VUE_APP_RELEASE,
    integrations: [
      new BrowserTracing({
        // TODO: we don't track backend APIs now as it's creating too many OPTIONS/Preflight request
        tracingOrigins: ['dont-track-anything']
      })
    ],
    trackComponents: true,
    tracesSampleRate: 0.1
  });
}

const init = () => {
  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app');
};
