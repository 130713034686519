import { ActionTree, Module, MutationTree } from 'vuex';

import {
  ClientInvoice,
  ClientInvoiceQuery,
  ClientInvoiceState,
  ClientNotice,
  ClientNoticesQuery,
  DetailClientInvoice
} from '@/interfaces/client-invoice';
import { BaseFilter } from '@/interfaces/filter';
import { RootState } from '@/interfaces/root-state';
import API from '@/services/api';

export const state: ClientInvoiceState = {
  data: [],
  filteredData: [],
  filter: {
    search: ''
  },
  detailData: [],
  filteredDetailData: [],
  notices: []
};

export const actions: ActionTree<ClientInvoiceState, RootState> = {
  async loadData(
    { commit, dispatch, state, rootState },
    query: ClientInvoiceQuery = {
      from: new Date(0).toISOString(),
      to: new Date().toISOString(),
      organizationNames: []
    }
  ) {
    dispatch('showGlobalLoader', true, { root: true });
    query.organizationNames = rootState.orgNames;
    const data = await API.getClientInvoiceSummary(query);
    commit('setData', data);

    dispatch('filterData', state.filter);
    dispatch('showGlobalLoader', false, { root: true });
  },

  async loadDetailData(
    { commit, dispatch, state, rootState },
    query: ClientInvoiceQuery = {
      from: new Date(0).toISOString(),
      to: new Date().toISOString(),
      organizationNames: []
    }
  ) {
    dispatch('showGlobalLoader', true, { root: true });
    query.organizationNames = rootState.orgNames;
    const detailClientInvoices = await API.getDetailClientInvoice(query);
    commit('setDetailData', detailClientInvoices);

    dispatch('filterData', state.filter);
    dispatch('showGlobalLoader', false, { root: true });
  },

  filterData({ commit, dispatch }, filter: BaseFilter) {
    dispatch('showGlobalLoader', true, { root: true });
    commit('setFilter', filter);
    commit('filterData');
    commit('filterDetailData');
    dispatch('showGlobalLoader', false, { root: true });
  },

  async loadNoticesData(
    { commit, dispatch, rootState },
    query: ClientNoticesQuery = {
      from: new Date(0).toISOString(),
      to: new Date().toISOString(),
      organizationNames: []
    }
  ) {
    dispatch('showGlobalLoader', true, { root: true });

    query.organizationNames = rootState.orgNames;
    const data = await API.getClientNotices(query);
    commit('setNoticesData', data);

    dispatch('showGlobalLoader', false, { root: true });
  }
};

export const mutations: MutationTree<ClientInvoiceState> = {
  setData: (state, data: ClientInvoice[]) => {
    state.data = data;
  },

  setFilter: (state, filter: BaseFilter) => {
    state.filter = filter;
  },

  filterData: (state) => {
    if (!state.filter.search) {
      state.filteredData = state.data;
    }

    state.filteredData = state.data.filter((dataItem: ClientInvoice) => {
      const { client } = dataItem;
      return client.toLowerCase().includes(state.filter.search.toLowerCase());
    });
  },

  setDetailData: (state, data: DetailClientInvoice[]) => {
    state.detailData = data;
  },

  filterDetailData: (state) => {
    if (!state.filter.search) {
      state.filteredDetailData = state.detailData;
    }

    state.filteredDetailData = state.detailData.filter((dataItem: DetailClientInvoice) => {
      const { organizationName } = dataItem;
      return organizationName.toLowerCase().includes(state.filter.search.toLowerCase());
    });
  },

  setNoticesData: (state, data: ClientNotice[]) => {
    state.notices = data;
  }
};

export const clientInvoice: Module<ClientInvoiceState, RootState> = { namespaced: true, state, actions, mutations };
