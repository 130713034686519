import { AnalyticNameType } from '@/enums/analytic';
import { OutputNameType } from '@/enums/output';

export enum SurveyStatus {
  Processing = 'Processing',
  WaitingReview = 'Waiting Review',
  NeedsCuration = 'Needs curation',
  InCuration = 'In curation',
  CurationDone = 'Curation Done',
  SupervisorApproved = 'Supervisor Approved',
  Rejected = 'Rejected',
  Published = 'Published'
}

export type SurveyStatusType =
  | SurveyStatus.Processing
  | SurveyStatus.WaitingReview
  | SurveyStatus.NeedsCuration
  | SurveyStatus.InCuration
  | SurveyStatus.CurationDone
  | SurveyStatus.SupervisorApproved
  | SurveyStatus.Rejected
  | SurveyStatus.Published;

export interface Survey {
  key?: number;
  surveyId: string;
  parcelName: string;
  parcelId: string;
  analyticType: AnalyticNameType;
  OutputNameType: OutputNameType;
  status: SurveyStatusType;
  progress: number;
  orthoQuality: number;
  analyticQuality: number;
  area: number;
  tilesNum: number;
  curated: number;
  notes: string;
  curatorName: string;
  curationTime: number;
  curationStartTime: string;
  curationEndTime: string;
  clientNotes: string;
  invoice: boolean;
  outputs: OutputNameType[];
  outputPublished: {
    [key: string]: boolean; // key is OutputNameType
  };
}

export type CanBeUpdatedStatusType =
  | SurveyStatus.WaitingReview
  | SurveyStatus.NeedsCuration
  | SurveyStatus.InCuration
  | SurveyStatus.CurationDone
  | SurveyStatus.SupervisorApproved
  | SurveyStatus.Rejected
  | SurveyStatus.Published;

export interface SurveyUpdateData {
  analyticQuality?: number;
  analyticType: AnalyticNameType;
  orthoQuality?: number;
  notes?: string;
  status?: CanBeUpdatedStatusType;
  clientNotes?: string;
  invoice?: boolean;
  outputPublished?: {
    [key: string]: boolean; // key is OutputNameType
  };
}
