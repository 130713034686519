
























































































































































































































































































































































import { Subject, race, Subscription } from 'rxjs';
import { bufferWhen, filter, bufferCount, throttleTime, debounceTime, bufferTime } from 'rxjs/operators';
import { Modal } from 'ant-design-vue';
import { Component, Vue } from 'vue-property-decorator';
import JsonCSV from 'vue-json-csv';
import {
  AligningStatus,
  Cluster,
  ClusterStatus,
  PhaseStatus,
  ClusterPhaseKey,
  ClusterStatusPhaseType,
  ClusterPhaseType
} from '@/interfaces/cluster';
import { AnalyticName, AnalyticNameType } from '@/enums/analytic';
import { OutputType } from '@/enums/output';
import { SurveyStatus, Survey, CanBeUpdatedStatusType } from '@/interfaces/survey';

import { ProgressStatusType, ProgressStatus } from '@/enums/progress-status';
import EditableCell from '@/components/EditableCell.vue';
import ClusterReport from '@/components/ClusterReport.vue';
import ColsSvg from '@/assets/icons/cols.svg';
import API from '@/services/api';
import Review from '@/services/review';
import { ClusterUpdateResponse } from '@/interfaces/cluster-update-response';
import Socket from '@/services/socket';
import { CheckBoxEvent, MenuEvent } from '@/interfaces/event';
import { TableFilter, TablePagination } from '@/interfaces/table';
import { filterTableList } from '@/services/utils';
import moment from 'moment';

const columns = [
  { title: 'Process', width: 375, key: 'process', scopedSlots: { customRender: 'process' } },
  { title: 'Action', width: 170, key: 'actions', scopedSlots: { customRender: 'actions' } }
];

const innerColumns = [
  { title: 'SurveyID', dataIndex: 'surveyId', key: 'surveyId' },
  { title: 'Parcel', dataIndex: 'parcelName', key: 'parcelName', scopedSlots: { customRender: 'parcelName' } },
  { title: 'Analytic', dataIndex: 'analyticType', key: 'analyticType' },
  { title: 'Products', width: 170, dataIndex: 'outputs', key: 'outputs', scopedSlots: { customRender: 'outputs' } },
  { title: 'Ha', dataIndex: 'area', key: 'area', scopedSlots: { customRender: 'area' } },
  { title: 'Tiles', dataIndex: 'tilesNum', key: 'tilesNum' },
  { title: 'Curated', dataIndex: 'curated', key: 'curated' },
  { title: 'Curator', dataIndex: 'curatorName', key: 'curatorName', scopedSlots: { customRender: 'curator' } },
  { title: 'Notes', dataIndex: 'notes', key: 'notes', scopedSlots: { customRender: 'notes' } },
  { title: 'Client Notes', dataIndex: 'clientNotes', key: 'clientNotes', scopedSlots: { customRender: 'clientNotes' } },
  {
    title: 'Ortho quality',
    dataIndex: 'orthoQuality',
    key: 'orthoQuality',
    scopedSlots: { customRender: 'orthoQuality' },
    width: 100
  },
  {
    title: 'Analytic quality',
    dataIndex: 'analyticQuality',
    width: 140,
    key: 'analyticQuality',
    scopedSlots: { customRender: 'analyticQuality' }
  },
  {
    title: 'Invoice',
    dataIndex: 'invoice',
    key: 'invoice',
    scopedSlots: { customRender: 'invoice' }
  },
  { title: 'Status', dataIndex: 'status', width: 200, key: 'status', scopedSlots: { customRender: 'status' } },

  { title: 'Review', scopedSlots: { customRender: 'review' } }
];

export interface Column {
  title: string;
  dataIndex?: string;
  key: string;
  scopedSlots?: { customRender?: string };
  sorter?: (a: unknown, b: unknown) => number;
  filters?: { text?: string; value?: string | number }[];
  onFilter?: (value: string, record: unknown) => boolean;
  order?: number;
}

export interface ProcessInfoData {
  key: string;
  time?: number;
  startTime?: string;
  endTime?: string;
}

export interface CsvData extends Survey {
  clusterId: string;
  farmName: string;
  organizationName: string;
  uploadingEnd: string;
}

@Component({
  components: {
    downloadCsv: JsonCSV,
    EditableCell,
    ColsSvg,
    ClusterReport
  }
})
export default class OperatorTable extends Vue {
  tableScrollOpt = { y: window.innerHeight - 250 };
  updateSubscription: Subscription;
  updateSource$: Subject<Cluster>;
  fileName = `${new Date().getTime()}.csv`;
  tableFilter: TableFilter = {}; // filters chosen on table, uses only for export data what user currently see

  private innerColumns = innerColumns;
  private columns: Column[] = columns;
  // mapping columns in csv

  surveyStatuses = [
    {
      text: 'Waiting Review',
      value: SurveyStatus.WaitingReview
    },
    {
      text: 'Needs Curation',
      value: SurveyStatus.NeedsCuration
    },
    {
      text: 'In Curation',
      value: SurveyStatus.InCuration
    },
    {
      text: 'Curation Done',
      value: SurveyStatus.CurationDone
    },
    {
      text: 'Supervisor Approved',
      value: SurveyStatus.SupervisorApproved
    },
    {
      text: 'Published',
      value: SurveyStatus.Published
    },
    {
      text: 'Rejected',
      value: SurveyStatus.Rejected
    }
  ];

  labels = {
    id: 'id',
    clusterId: 'clusterId',
    area: 'area',
    farmName: 'farm',
    surveyId: 'surveyId',
    status: 'status',
    organizationName: 'organizationName',
    uploadingEnd: 'Date uploaded',
    analyticType: 'analyticType',
    outputs: 'Products',
    notes: 'Notes'
  };
  exportingFields = [
    'id',
    'clusterId',
    'surveyId',
    'area',
    'farmName',
    'status',
    'organizationName',
    'uploadingEnd',
    'analyticType',
    'outputs',
    'notes'
  ];
  priorities = ['Lowest', 'Low', 'Normal', 'High', 'Highest'];
  aligningStatus = AligningStatus;
  surveyStatus = SurveyStatus;
  clusterStatus = ClusterStatus;
  // Header selection props
  // TODO: setup selection of columns
  // solve issue with custom slot rendering
  // fix sorting after checking
  groupOptions = [
    { label: 'Cluster ID', value: 'clusterId', scopedSlots: { customRender: 'clusterId' }, order: 10 },
    {
      label: 'Client',
      value: 'client',
      scopedSlots: { customRender: 'client' },
      order: 9
    },
    { label: 'Farm', value: 'farmName', order: 8 },
    {
      label: 'Ha',
      value: 'area',
      sorter: (a: Cluster, b: Cluster): number => a.area - b.area,
      scopedSlots: { customRender: 'area' },
      order: 7
    },
    {
      label: 'Status',
      value: 'status',
      width: 120,
      scopedSlots: { customRender: 'status' },
      filters: [
        { text: 'Uploading', value: ClusterStatus.Uploading },
        { text: 'Stitching', value: ClusterStatus.Stitching },
        { text: 'To be Aligned', value: ClusterStatus.ToBeAligned },
        { text: 'Aligning', value: ClusterStatus.Aligning },
        { text: 'Processing', value: ClusterStatus.Processing },
        { text: 'Waiting Curation', value: ClusterStatus.WaitingCuration },
        { text: 'In Curation', value: ClusterStatus.InCuration },
        { text: 'Published', value: ClusterStatus.Published },
        { text: 'Rejected', value: ClusterStatus.Rejected }
      ],
      onFilter: (value: string, record: Cluster): boolean => record.status.indexOf(value) === 0,
      order: 6
    },
    { label: 'Notes', value: 'notes', width: 250, scopedSlots: { customRender: 'notes' }, order: 5 },
    {
      label: 'Priority',
      value: 'priority',
      width: 150,
      scopedSlots: { customRender: 'priority' },
      filters: [
        // value should be string, doesn't work properly with number without error handling
        { text: 'Lowest', value: '0' },
        { text: 'Low', value: '1' },
        { text: 'Normal', value: '2' },
        { text: 'High', value: '3' },
        { text: 'Highest', value: '4' }
      ],
      onFilter: (value: string | number, record: Cluster): boolean => record.priority == value,
      order: 4
    },
    {
      label: 'Date uploaded',
      value: 'uploading.endTime',
      sorter: (a: Cluster, b: Cluster): number => {
        return new Date(a.uploading.endTime).getTime() - new Date(b.uploading.endTime).getTime();
      },
      scopedSlots: { customRender: 'uploadedDate' },
      order: 3
    },
    {
      label: 'Client Notes',
      value: 'clientNotes',
      width: 250,
      scopedSlots: { customRender: 'clientNotes' },
      order: 2
    }
  ];
  defaultCheckedList = ['clusterId', 'client', 'farmName', 'area', 'status', 'notes', 'priority'];
  indeterminate = true;
  checkAll = false;
  checkedList = this.defaultCheckedList;
  clusterReportVisible = false;
  selectedClusterId = null;

  get clusters(): Cluster[] {
    return this.$store.getters['operator/filteredClusters'];
  }

  get from() {
    return this.$store.state.from;
  }

  public onFromChange(date): void {
    if (date) {
      this.$store.dispatch('setFrom', date);
      this.loadClusters();
    }
  }

  disabledFromDate(date) {
    return date && date > moment.utc().startOf('day');
  }

  get exportData(): CsvData[] {
    const clusters = filterTableList(this.clusters, this.tableFilter);
    // convert clusters list to surveys with cluster
    const surveys = clusters.reduce((acc, cluster: Cluster) => {
      const surveysPerCluster = cluster.surveys.map((survey) => ({
        clusterId: cluster.clusterId,
        farmName: cluster?.farmName,
        organizationName: cluster?.client?.organizationName,
        uploadingEnd: cluster?.uploading?.endTime,
        ...survey
      }));
      acc.push(...surveysPerCluster);
      return acc;
    }, []);
    return surveys;
  }

  setSurveyStatusFilter(surveyStatuses: string[]): void {
    const filter = Object.assign({}, this.$store.state.operator.filter, { surveyStatuses });
    this.$store.dispatch('operator/filterClusters', filter);
  }

  onSearch(value: string): void {
    const filter = Object.assign({}, this.$store.state.operator.filter, { search: value });
    this.$store.dispatch('operator/filterClusters', filter);
  }

  private loadClusters(): void {
    if (this.from) {
      const fromDate = this.from?.utc().format();
      this.$store.dispatch('operator/loadClusters', fromDate);
      this.updateColumns();
    }
  }

  async mounted(): Promise<void> {
    this.loadClusters();
    this.updateSource$ = new Subject();

    this.updateSubscription = this.updateSource$
      .pipe(
        bufferWhen(() => {
          return race(
            this.updateSource$.pipe(bufferTime(10000)),
            this.updateSource$.pipe(bufferCount(20)),
            this.updateSource$.pipe(throttleTime(1000), debounceTime(2000))
          );
        }),
        filter((updatedList) => updatedList.length > 0)
      )
      .subscribe((updatedList) => {
        this.$store.dispatch('operator/updateClusters', updatedList);
      });
    // listen socket events
    Socket.client.on('onDelete', this.onDelete.bind(this));
    Socket.client.on('onClusterUpdate', this.onClusterUpdate.bind(this));
  }

  getCurrentStepIndex(cluster: Cluster): number {
    const doneIndex = 3;
    const stepsMap = {
      [this.clusterStatus.Uploading]: 0,
      [this.clusterStatus.Stitching]: 1,
      [this.clusterStatus.Aligning]: 2,
      [this.clusterStatus.ToBeAligned]: 1,
      [this.clusterStatus.Processing]: doneIndex, // Aligning done
      [this.clusterStatus.WaitingCuration]: doneIndex, // Aligning done
      [this.clusterStatus.InCuration]: doneIndex, // Aligning done
      [this.clusterStatus.Published]: doneIndex, // Aligning done
      [this.clusterStatus.Rejected]: doneIndex // Aligning done
    };

    return stepsMap[cluster.status];
  }

  getCurrentStep(cluster: Cluster): ClusterPhaseType {
    const keyPropMap = {
      [this.clusterStatus.Uploading]: ClusterPhaseKey.uploading,
      [this.clusterStatus.Stitching]: ClusterPhaseKey.stitching,
      [this.clusterStatus.Aligning]: ClusterPhaseKey.aligning
    };
    const currentKey = keyPropMap[cluster.lastPhase];
    return cluster[currentKey];
  }

  getCurrentStepName(cluster: Cluster): ClusterStatusPhaseType {
    return cluster.lastPhase || this.clusterStatus.Aligning;
  }

  getCurrentStepElapsedTime(cluster: Cluster): number {
    const currentStep = this.getCurrentStep(cluster);

    return currentStep?.elapsedTime;
  }

  getCurrentStepProgress(cluster: Cluster): number {
    const currentStep = this.getCurrentStep(cluster);
    return currentStep?.progress;
  }

  getCurrentStepProgressStatus(cluster: Cluster): ProgressStatusType {
    const phaseStatusToProgressStatus = {
      [PhaseStatus.InProgress]: ProgressStatus.active,
      [PhaseStatus.Finished]: ProgressStatus.success,
      [PhaseStatus.Failed]: ProgressStatus.exception,
      [PhaseStatus.Cancelled]: ProgressStatus.exception,
      default: ProgressStatus.normal
    };
    const currentStep = this.getCurrentStep(cluster);
    const phaseStatusKey = currentStep.status || 'default'; // PhaseStatus or AligningStatus values
    return phaseStatusToProgressStatus[phaseStatusKey];
  }

  getProcessInfoData(cluster: Cluster): ProcessInfoData[] {
    const caclTime = (end: string, start: string): number => {
      const startTime = new Date(start).getTime();
      const endTime = new Date(end).getTime();
      if (endTime < startTime) return undefined;
      return endTime - startTime;
    };
    return [
      {
        key: ClusterPhaseKey.uploading,
        time: caclTime(cluster.uploading.endTime, cluster.uploading.startTime),
        startTime: cluster.uploading.startTime,
        endTime: cluster.uploading.endTime
      },
      {
        key: ClusterPhaseKey.stitching,
        time: caclTime(cluster.stitching.endTime, cluster.stitching.startTime),
        startTime: cluster.stitching.startTime,
        endTime: cluster.stitching.endTime
      },
      {
        key: ClusterPhaseKey.aligning,
        time: caclTime(cluster.aligning.endTime, cluster.aligning.startTime),
        startTime: cluster.aligning.startTime,
        endTime: cluster.aligning.endTime
      },
      { key: 'Total' }
    ];
  }

  handleAnalyticQualityChange(survey: Survey, value: number): void {
    API.updateSurvey(survey.surveyId, { analyticType: survey.analyticType, analyticQuality: value });
  }

  handleClusterNotesChange(clusterId: string, value: string): void {
    API.updateCluster(clusterId, { notes: value });
  }

  handleClusterClientNotesChange(clusterId: string, value: string): void {
    API.updateCluster(clusterId, { clientNotes: value });
  }

  handleOrthoQualityChange(survey: Survey, value: number): void {
    if (value) {
      API.updateSurvey(survey.surveyId, { analyticType: survey.analyticType, orthoQuality: value });
    }
  }

  handleSurveyOutputPublish(survey: Survey, output: OutputType): void {
    if (!survey.outputPublished) survey.outputPublished = {};
    survey.outputPublished[output] = !survey.outputPublished[output];
    API.updateSurvey(survey.surveyId, { outputPublished: survey.outputPublished, analyticType: survey.analyticType });
  }

  handleInvoiceChange(survey: Survey): void {
    // Toggle value
    survey.invoice = !survey.invoice;

    if (survey.invoice === false) {
      Modal.confirm({
        title: `Are  you sure you want to uncheck (${survey.surveyId})? Concequences?`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          API.updateSurvey(survey.surveyId, { invoice: survey.invoice, analyticType: survey.analyticType });
          return Promise.resolve();
        },
        onCancel() {
          // revert to checked
          survey.invoice = true;
        }
      });
      return;
    }

    API.updateSurvey(survey.surveyId, { invoice: survey.invoice, analyticType: survey.analyticType });
  }

  handleSurveyNotesChange(survey: Survey, value: string): void {
    API.updateSurvey(survey.surveyId, { analyticType: survey.analyticType, notes: value });
  }

  handleSurveyClientNotesChange(survey: Survey, value: string): void {
    API.updateSurvey(survey.surveyId, { analyticType: survey.analyticType, clientNotes: value });
  }

  handleSurveyStatusChange(survey: Survey, value: CanBeUpdatedStatusType): void {
    if (value === SurveyStatus.Rejected) {
      Modal.confirm({
        title: `Are you sure to reject survey (${survey.surveyId})?`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          API.updateSurvey(survey.surveyId, { analyticType: survey.analyticType, status: value });
          return Promise.resolve();
        }
      });
      return;
    }
    API.updateSurvey(survey.surveyId, { analyticType: survey.analyticType, status: value });
  }

  private rejectCluster(clusterId: string): Promise<ClusterUpdateResponse> {
    return new Promise<ClusterUpdateResponse>((resolve, reject) => {
      Modal.confirm({
        title: `Are you sure to reject cluster (${clusterId})?`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          API.rejectClusterById(clusterId).then(resolve).catch(reject);
          return Promise.resolve();
        },
        onCancel() {
          resolve(null);
          return Promise.resolve();
        }
      });
    });
  }

  async handleActionMenuClick(clusterId: string, e: MenuEvent): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    switch (e.key) {
      case 'publish':
        await API.publishClusterById(clusterId);
        break;

      case 'reject':
        await this.rejectCluster(clusterId);
        break;

      case 'delete':
        await API.deleteClusterById(clusterId);
        break;

      default:
        break;
    }
    this.$store.dispatch('showGlobalLoader', false);
  }

  async handleActionButtonClick(clusterId: string): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    await API.publishClusterById(clusterId);
    this.$store.dispatch('showGlobalLoader', false);
  }

  handlePriorityChange(clusterId: string, value: number): void {
    API.updateCluster(clusterId, { priority: value });
  }

  handleCopy(clusterId: string): void {
    this.$copyText(clusterId); // VueClipboard
  }

  handleProcessMenuClick(clusterId: string, e: MenuEvent): void {
    switch (e.key) {
      case 'Align':
        Review.openAlignment(clusterId);
        break;

      case 'StitchingReport':
        Review.openStitchingReport(clusterId);
        break;

      case 'Download':
        Review.downloadOrtho(clusterId);
        break;

      case 'ClusterReport':
        this.clusterReportVisible = true;
        this.selectedClusterId = clusterId;
        break;

      default:
        break;
    }
  }

  handleClusterReportOk(): void {
    this.clusterReportVisible = false;
  }

  handleSurveyReview(surveyId: string, analyticType: AnalyticNameType): void {
    switch (analyticType) {
      case AnalyticName.sowing:
        Review.openSowingCuration(surveyId);
        break;

      case AnalyticName.weeds:
        Review.openWeedsCuration(surveyId);
        break;

      default:
        break;
    }
  }

  updateColumns(): void {
    const extraCols = this.checkedList.map((checkedItem) => {
      const option = this.groupOptions.find((groupOption) => {
        return groupOption.value === checkedItem;
      });

      return {
        title: option.label,
        dataIndex: option.value,
        key: option.value,
        ...option
      } as Column;
    });

    const sortedExtraCols = extraCols.sort((a: Column, b: Column): number => b.order - a.order);
    this.columns = [
      ...sortedExtraCols.slice(0, 5),
      ...columns.slice(0, 1),
      ...sortedExtraCols.slice(5, sortedExtraCols.length),
      ...columns.slice(1, columns.length)
    ];
    // slice use to keep sorting of columns
  }

  onColsChange(checkedList: string[]): void {
    this.indeterminate = !!checkedList.length && checkedList.length < this.groupOptions.length;
    this.checkAll = checkedList.length === this.groupOptions.length;
    this.updateColumns();
  }

  onColsCheckAllChange(e: CheckBoxEvent): void {
    this.checkedList = e.target.checked ? this.groupOptions.map((opt) => opt.value) : [];
    this.indeterminate = false;
    this.checkAll = e.target.checked;
    this.updateColumns();
  }

  private onClusterUpdate(data: Cluster): void {
    this.updateSource$.next(data);
  }

  private onDelete(clusterId: string): void {
    this.$store.dispatch('operator/deleteCluster', clusterId);
  }

  onTableChange(pagination: TablePagination, filters: TableFilter): void {
    // function has 3 param as sorter: TableSorter
    this.tableFilter = filters;
  }

  beforeDestroy(): void {
    this.updateSubscription.unsubscribe();
    Socket.client.off('onDelete');
    Socket.client.off('onClusterUpdate');
  }
}
