import { ActionTree, Module, MutationTree } from 'vuex';

import { Filter } from '@/interfaces/filter';
import { ManagementItem, ManagementQuery, ManagementState } from '@/interfaces/management';
import { RootState } from '@/interfaces/root-state';
import API from '@/services/api';

export const state: ManagementState = {
  from: '',
  to: '',
  data: [],
  filteredData: [],
  filter: {
    search: ''
  }
};

export const actions: ActionTree<ManagementState, RootState> = {
  async loadData({ commit, dispatch, state }, query: ManagementQuery) {
    dispatch('showGlobalLoader', true, { root: true });
    const data = await API.getManagementData(query);
    commit('setData', data);
    dispatch('filterData', state.filter);
    dispatch('showGlobalLoader', false, { root: true });
  },

  filterData({ commit, dispatch }, filter: Filter) {
    dispatch('showGlobalLoader', true, { root: true });
    commit('filterData', filter);
    dispatch('showGlobalLoader', false, { root: true });
  }
};

export const mutations: MutationTree<ManagementState> = {
  setData: (state, data: ManagementItem[]) => {
    state.data = data;
  },

  filterData: (state, filter: Filter) => {
    if (!filter.search) {
      state.filteredData = state.data;
    }
    state.filter = filter;
    state.filteredData = state.data.filter((dataItem: ManagementItem) => {
      const { client } = dataItem;
      return client.toLowerCase().includes(filter.search.toLowerCase());
    });
  }
};

export const management: Module<ManagementState, RootState> = { namespaced: true, state, actions, mutations };
