





























import { Component, Vue, Prop } from 'vue-property-decorator';
import csv from 'csvtojson';
import API from '@/services/api';
import { SatSurvey } from '@/interfaces/satsurvey';
import { SatAnalyticName } from '@/enums/satanalytic';

export interface Column {
  title: string;
  dataIndex: string;
  key: string;
  scopedSlots?: {
    customRender?: string;
  };
}

export interface CSVColumn {
  key: string;
  values: any[];
}

const successfulStatusCode = '[0]';

@Component
export default class CsvTable extends Vue {
  @Prop({ default: '' }) readonly csvData!: string;
  @Prop({ default: null }) readonly satSurvey!: SatSurvey;
  private columns: Column[] = [];
  dataSource = [];
  isNDVIChartModalVisible = false;
  selectedNDVIChartImgUrl = null;

  async mounted(): Promise<void> {
    this.dataSource = await csv().fromString(this.csvData);
    let columns = this.mapToColumns(this.dataSource).map((col) => {
      if (col.key === 'id') {
        return {
          title: col.key,
          dataIndex: col.key,
          key: col.key,
          scopedSlots: { customRender: 'id' }
        };
      }

      if (col.key === 'status codes') {
        return {
          title: col.key,
          dataIndex: col.key,
          key: col.key,
          scopedSlots: { customRender: 'statusCodes' },
          filters: [
            {
              text: 'Success',
              value: 'success'
            },
            {
              text: 'Fail',
              value: 'fail'
            }
          ],
          onFilter: (value: string, record): boolean => {
            if (value === 'success') {
              return record[col.key].indexOf(successfulStatusCode) === 0;
            }

            return !record[col.key].includes(successfulStatusCode);
          }
        };
      }

      if (this.satSurvey.analyticType === SatAnalyticName.flowering && col.key !== 'parcelID') {
        return {
          title: col.key,
          dataIndex: col.key,
          key: col.key,
          scopedSlots: { customRender: 'season' }
        };
      }

      if (col.key === 'parcelID') {
        return {
          title: col.key,
          dataIndex: col.key,
          key: col.key,
          width: '300px'
        };
      }

      return {
        title: col.key,
        dataIndex: col.key,
        key: col.key
      };
    });

    if (this.satSurvey.analyticType === SatAnalyticName.flowering) {
      const seasonColumns = columns.slice(0, columns.length - 1);
      const parcelIDColumn = columns[columns.length - 1];
      columns = [parcelIDColumn, ...seasonColumns];
    }

    this.columns = columns;
  }

  mapToColumns(data: any[]): CSVColumn[] {
    const uniqueValues: Map<string, Set<any>> = new Map();
    data.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (!uniqueValues.has(key)) {
          uniqueValues.set(key, new Set());
        }
        uniqueValues.set(key, uniqueValues.get(key).add(row[key]));
      });
    });
    return Array.from(uniqueValues.entries()).map(([key, values]) => ({
      key,
      values: Array.from(values)
    }));
  }

  getNDVIChartImgUrl(parcelId: string): string {
    return API.getNematodesNDVIChartImageUrl(this.satSurvey.unitId, this.satSurvey.date, parcelId);
  }

  showNDVIChart(parcelId: string): void {
    this.isNDVIChartModalVisible = true;
    this.selectedNDVIChartImgUrl = this.getNDVIChartImgUrl(parcelId);
  }

  handleClose(): void {
    this.isNDVIChartModalVisible = false;
    this.selectedNDVIChartImgUrl = '';
  }

  get chaerModalWidth(): number {
    return window.innerWidth - 100 || 520;
  }

  isStatusCodeFailed(statusCode: string): boolean {
    return statusCode.indexOf(successfulStatusCode) === -1;
  }
}
