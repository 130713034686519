export enum OutputType {
  PHL = 'phl',
  Gaps = 'sowing',
  Weeds = 'weeds',
  WeedsClasses = 'weeds_classes'
}
export type OutputNameType = OutputType.PHL | OutputType.Gaps | OutputType.Weeds | OutputType.WeedsClasses;

export enum ProductType {
  PHL = 'phl',
  PlantingGaps = 'sowing',
  Weeds = 'weeds',
  WeedsClasses = 'weeds_classes',
  OrthoPix4d = 'ortho pix4d',
  OrthoMetashape = 'ortho metashape'
}

export enum ProductTypeLabel {
  phl = 'PHL',
  sowing = 'Planting Gaps',
  weeds = 'Weeds',
  weeds_classes = 'Weeds Classes',
  'ortho pix4d' = 'Ortho pix4d',
  'ortho metashape' = 'Ortho metashape'
}
