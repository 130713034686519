import Vue from 'vue';
import Vuex from 'vuex';
import { UserInfo } from '@/interfaces/user-info';
import { RootState } from '@/interfaces/root-state';
import { management } from './modules/management';
import { operator } from './modules/operator';
import { client } from './modules/client';
import { clientInvoice } from './modules/client-invoice';
import { invoice } from './modules/invoice';
import { satsurveys } from './modules/satsurveys';
import { satsubscriptions } from './modules/satsubscriptions';
import { Role } from '@/enums/role';

import API from '@/services/api';
import { message } from 'ant-design-vue';
import moment from 'moment';
import * as Sentry from '@sentry/vue';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
    showGlobalLoader: false,
    userInfo: null,
    orgNames: [],
    from: moment().subtract(3, 'M').utc(),
    fromClientView: moment().subtract(3, 'M').utc(),
    fromSatSurveys: moment().subtract(10, 'days').utc()
  },
  mutations: {
    showGlobalLoader(state, payload) {
      state.showGlobalLoader = payload;
    },
    setUserInfo: (state, userInfo: UserInfo) => {
      state.userInfo = { ...state.userInfo, ...userInfo };
    },
    setUserOrgs: (state, orgNames: string[]) => {
      state.orgNames = orgNames;
    },
    setFrom: (state, from: moment.Moment) => {
      state.from = from;
    },
    setFromClientView: (state, from: moment.Moment) => {
      state.fromClientView = from;
    },
    setSatSurveysFrom: (state, from: moment.Moment) => {
      state.fromSatSurveys = from;
    }
  },
  actions: {
    showGlobalLoader({ commit }, isShow) {
      commit('showGlobalLoader', isShow);
    },
    setFrom({ commit }, from: moment.Moment) {
      commit('setFrom', from);
    },
    setFromClientView({ commit }, from: moment.Moment) {
      commit('setFromClientView', from);
    },
    setSatSurveysFrom({ commit }, from: moment.Moment) {
      commit('setSatSurveysFrom', from);
    },
    async authorize({ commit, dispatch }) {
      dispatch('showGlobalLoader', true);
      try {
        const res = await API.authorize();
        const userInfo = res.UserInfo;
        commit('setUserInfo', userInfo);
        Sentry.setUser({ email: userInfo.Email, username: userInfo.FirstName + ' ' + userInfo.LastName });
        const isDefaultRole =
          !res.UserInfo.Roles || !res.UserInfo.Roles.find((role) => role == Role.admin || role == Role.dashboard);

        if (isDefaultRole) {
          const countries = await API.getCountries();
          if (countries && countries.length > 0) {
            const orgNames = await API.getUserOrgs(countries[0].id);
            commit('setUserOrgs', orgNames);
          }
        }
      } catch {
        window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
      } finally {
        dispatch('showGlobalLoader', false);
      }
    }
  },
  modules: {
    operator,
    management,
    client,
    invoice,
    clientInvoice,
    satsurveys,
    satsubscriptions
  }
});
