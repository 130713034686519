import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { Filter } from '@/interfaces/filter';
import { RootState } from '@/interfaces/root-state';
import { SatSurvey, SatSurveyState } from '@/interfaces/satsurvey';
import API from '@/services/api';

export const state: SatSurveyState = {
  data: [],
  filteredData: [],
  filter: {
    search: ''
  }
};

export const actions: ActionTree<SatSurveyState, RootState> = {
  async loadData({ commit, dispatch, state }, fromDate?: string) {
    dispatch('showGlobalLoader', true, { root: true });
    const data = await API.getSatSurveys(fromDate);
    commit('setData', data);
    dispatch('filterData', state.filter);
    dispatch('showGlobalLoader', false, { root: true });
  },

  filterData({ commit, dispatch }, filter: Filter) {
    dispatch('showGlobalLoader', true, { root: true });
    commit('filterData', filter);
    dispatch('showGlobalLoader', false, { root: true });
  }
};

export const mutations: MutationTree<SatSurveyState> = {
  setData: (state, data: SatSurvey[]) => {
    state.data = data;
  },

  filterData: (state, filter: Filter) => {
    if (!filter.search) {
      state.filteredData = state.data;
    }
    if (!state.data) {
      return;
    }
    state.filter = filter;
    state.filteredData = state.data.filter((dataItem: SatSurvey) => {
      const { organizationName = '', unitName = '', farmName = '', notes = '' } = dataItem;
      return (
        organizationName.toLowerCase().includes(filter.search.toLowerCase()) ||
        unitName.toLowerCase().includes(filter.search.toLowerCase()) ||
        farmName.toLowerCase().includes(filter.search.toLowerCase()) ||
        notes.toLowerCase().includes(filter.search.toLowerCase())
      );
    });
  }
};

export const getters: GetterTree<SatSurveyState, RootState> = {
  getUnitFilterOptions: (state): string[] => {
    const uniqueNames = new Set([]);
    state.data.forEach((satSurvey) => {
      const { unitName } = satSurvey;
      uniqueNames.add(unitName);
    });

    return Array.from(uniqueNames).filter((value) => !!value);
  }
};

export const satsurveys: Module<SatSurveyState, RootState> = { namespaced: true, state, actions, mutations, getters };
