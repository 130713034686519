import { ActionTree, Module, MutationTree } from 'vuex';

import { Filter } from '@/interfaces/filter';
import { Invoice, InvoiceState, InvoiceQuery } from '@/interfaces/invoice';
import { RootState } from '@/interfaces/root-state';
import API from '@/services/api';

export const state: InvoiceState = {
  data: [],
  filteredData: [],
  filter: {
    search: ''
  }
};

export const actions: ActionTree<InvoiceState, RootState> = {
  async loadData({ commit, dispatch, state }, query: InvoiceQuery) {
    dispatch('showGlobalLoader', true, { root: true });
    const data = await API.getInvoiceData(query);
    commit('setData', data);
    dispatch('filterData', state.filter);
    dispatch('showGlobalLoader', false, { root: true });
  },

  filterData({ commit, dispatch }, filter: Filter) {
    dispatch('showGlobalLoader', true, { root: true });
    commit('filterData', filter);
    dispatch('showGlobalLoader', false, { root: true });
  }
};

export const mutations: MutationTree<InvoiceState> = {
  setData: (state, data: Invoice[]) => {
    state.data = data;
  },

  filterData: (state, filter: Filter) => {
    if (!filter.search) {
      state.filteredData = state.data;
    }
    state.filter = filter;
    state.filteredData = state.data.filter((dataItem: Invoice) => {
      const { client } = dataItem;
      return client.toLowerCase().includes(filter.search.toLowerCase());
    });
  }
};

export const invoice: Module<InvoiceState, RootState> = { namespaced: true, state, actions, mutations };
