import { Filter } from '@/interfaces/filter';
import { SatAnalyticNameType } from '@/enums/satanalytic';

export enum AreaType {
  ParcelType = 1,
  FarmType = 2,
  UnitType = 3
}

export interface Area {
  id: string;
  type: AreaType;
}

export interface SatSubscription {
  id: string;
  startDate: string;
  unitName: string;
  farmName: string;
  organizationName: string;
  detail: {
    date: string;
    analytics: SatAnalyticNameType;
    endDate: string;
  };
  areas: Area[];
}

export interface SatSubscriptionState {
  data: SatSubscription[];
  filteredData: SatSubscription[];
  filter: Filter;
}
