import Vue from 'vue';
import VueRouter, { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';

import ChartsPage from '@/components/ChartsPage.vue';
import ClientInvoiceSummary from '@/components/ClientInvoiceSummary.vue';
import ClientTable from '@/components/ClientTable.vue';
import InvoiceTable from '@/components/InvoiceTable.vue';
import ManagementTable from '@/components/ManagementTable.vue';
import OperatorTable from '@/components/OperatorTable.vue';
import SatSurveysTable from '@/components/SatSurveysTable.vue';
import SatSubscriptions from '@/components/SatSubscriptions.vue';
import PermissionDenied from '@/components/PermissionDenied.vue';
import { PageName } from '@/enums/page-name';
import store from '@/store';
import { hasRoles, hasUserOrg, isAdmin, isDashboardRole } from '@/store/utils';
import Dashboard from '@/views/Dashboard.vue';

Vue.use(VueRouter);

export function onlyAdmin(): boolean {
  const { state } = store;
  return hasRoles(state) && isAdmin(state);
}

export function onlyDashboardRole(): boolean {
  const { state } = store;
  return hasRoles(state) && isDashboardRole(state);
}

export function onlyUserWithOrg(): boolean {
  const { state } = store;
  if (hasRoles(state) && (isAdmin(state) || isDashboardRole(state))) {
    return true;
  }

  return hasUserOrg(state);
}

export interface AccessRule {
  condition: () => boolean;
}

export function accessMiddleware(accessRules: AccessRule[], redirectPage: PageName): NavigationGuard {
  return (to: Route, from: Route, next: NavigationGuardNext): void => {
    const isAnyPassed = accessRules.some((rule) => rule.condition());
    if (!isAnyPassed) {
      next({ name: redirectPage });
    }

    next();
  };
}

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      await store.dispatch('authorize');
      next();
    },
    children: [
      {
        path: '/operator',
        name: PageName.OPERATOR,
        component: OperatorTable,
        beforeEnter: accessMiddleware([{ condition: onlyAdmin }, { condition: onlyDashboardRole }], PageName.CLIENT)
      },
      {
        path: '/sat-surveys',
        name: PageName.SATSURVEYS,
        component: SatSurveysTable,
        beforeEnter: accessMiddleware([{ condition: onlyAdmin }, { condition: onlyDashboardRole }], PageName.CLIENT)
      },
      {
        path: '/sat-subscriptions',
        name: PageName.SATSUBSCIPTIONS,
        component: SatSubscriptions,
        beforeEnter: accessMiddleware([{ condition: onlyAdmin }, { condition: onlyDashboardRole }], PageName.CLIENT)
      },
      {
        path: '/client',
        name: PageName.CLIENT,
        component: ClientTable,
        beforeEnter: accessMiddleware([{ condition: onlyUserWithOrg }], PageName.PERMISSION_DENIED)
      },
      {
        path: '/management',
        name: PageName.MANAGEMENT,
        component: ManagementTable,
        beforeEnter: accessMiddleware([{ condition: onlyAdmin }, { condition: onlyDashboardRole }], PageName.CLIENT)
      },
      {
        path: '/invoice',
        name: PageName.INVOICE,
        component: InvoiceTable,
        beforeEnter: accessMiddleware([{ condition: onlyAdmin }, { condition: onlyDashboardRole }], PageName.CLIENT)
      },
      {
        path: '/charts',
        name: PageName.CHARTS,
        component: ChartsPage,
        beforeEnter: accessMiddleware([{ condition: onlyAdmin }, { condition: onlyDashboardRole }], PageName.CLIENT)
      },
      {
        path: '/client-invoice',
        name: PageName.CLIENT_INVOICE,
        component: ClientInvoiceSummary,
        beforeEnter: accessMiddleware([{ condition: onlyUserWithOrg }], PageName.CLIENT)
      },
      {
        path: '/permission-denied',
        name: PageName.PERMISSION_DENIED,
        component: PermissionDenied
      },
      { path: '*', redirect: '/operator' }
    ]
  },
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
