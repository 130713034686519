import { normalize } from 'normalizr';
import Vue from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { Cluster } from '@/interfaces/cluster';
import { Filter } from '@/interfaces/filter';
import { OperatorEntities, OperatorState } from '@/interfaces/operator';
import { RootState } from '@/interfaces/root-state';
import { Survey } from '@/interfaces/survey';
import API from '@/services/api';
import { clusterSchema } from '@/store/schema';
import { isMatchList, parseSearch } from '@/store/utils';

export interface normalizedResult {
  entities: OperatorEntities;
  result: string[];
}

export const state: OperatorState = {
  filter: {
    search: '',
    surveyStatuses: []
  },
  allIds: [],
  entities: {
    clusters: {},
    surveys: {}
  }
};

export const actions: ActionTree<OperatorState, RootState> = {
  async loadClusters({ commit, dispatch }, fromDate?: string) {
    dispatch('showGlobalLoader', true, { root: true });
    const clusters = await API.getAllClusters(fromDate);
    dispatch('showGlobalLoader', false, { root: true });

    commit('cleanClusters');
    if (!clusters || !clusters.length) {
      return;
    }
    const normalizedEntities = normalize(clusters, [clusterSchema]);
    commit('updateEntities', normalizedEntities);
  },
  updateClusters({ commit }, clusters: Cluster[]) {
    // don't show the spinner
    // updating can happens many times so user don't want to see spinner all that times
    const normalizedEntities = normalize(clusters, [clusterSchema]);
    commit('updateEntities', normalizedEntities);
  },
  deleteCluster({ commit }, clusterId: string) {
    commit('deleteCluster', clusterId);
  },
  filterClusters({ commit, dispatch }, filter: Filter) {
    dispatch('showGlobalLoader', true, { root: true });
    commit('setFilter', filter);
    dispatch('showGlobalLoader', false, { root: true });
  }
};

export const mutations: MutationTree<OperatorState> = {
  updateEntities(state, normalizedData: normalizedResult) {
    const { entities } = normalizedData;
    const newIds = Object.keys(entities.clusters).filter((id) => {
      const isExist = !!state.entities.clusters[id];
      return !isExist;
    });
    state.allIds = [...newIds, ...state.allIds];

    for (const type in entities) {
      for (const entity in entities[type]) {
        const oldObj = state.entities[type][entity] || {};
        const newObj = Object.assign(oldObj, entities[type][entity]);
        // Make sure new entities are also reactive
        Vue.set(state.entities[type], entity, newObj);
      }
    }
  },

  setFilter(state, filter) {
    state.filter = { ...state.filter, ...filter };
  },
  deleteCluster(state, deletedId: string) {
    state.allIds = [...state.allIds.filter((id) => id !== deletedId)];
  },
  cleanClusters() {
    state.allIds = [];
    state.entities.clusters = {};
  }
};

export const getters: GetterTree<OperatorState, RootState> = {
  clusterById: (state) => (clusterId: string): Cluster => {
    const cluster = state.entities.clusters[clusterId];
    return {
      ...cluster,
      surveys: cluster.surveys.map((surveyId) => {
        return state.entities.surveys[surveyId];
      })
    };
  },
  clusters: (state, getters): Cluster[] => {
    return state.allIds.map((id) => getters.clusterById(id));
  },
  filteredClusters: (state, getters): Cluster[] => {
    const { filter } = state;
    let filteredClusters = getters.clusters
      .filter((cluster) => {
        const {
          clusterId,
          client: { organizationName = '' },
          farmName,
          surveys,
          notes
        } = cluster;
        return parseSearch(filter.search).every((searchPart) => {
          const isClusterMatch = isMatchList([clusterId, organizationName, farmName, notes], searchPart);
          const surveyProperties = surveys
            .map(({ surveyId, parcelName, analyticType, notes }) => [surveyId, parcelName, analyticType, notes])
            .reduce((acc, current) => {
              return [...acc, ...current];
            }, []); // flat array of values
          const isSurveyMatch = isMatchList(surveyProperties, searchPart);
          return isClusterMatch || isSurveyMatch;
        });
      })
      .map((cluster) => {
        const isMatchAnyAnalytic = cluster.surveys.some(({ analyticType }) => {
          return parseSearch(filter.search).some((searchPart) => isMatchList([analyticType], searchPart));
        });
        if (!isMatchAnyAnalytic) {
          return { ...cluster };
        }
        // filter by analytic type
        // handle case when search string contains any of analytic type
        const surveys = cluster.surveys.filter(({ analyticType }) => {
          return parseSearch(filter.search).some((searchPart) => isMatchList([analyticType], searchPart));
        });
        return { ...cluster, surveys };
      });
    if (filter.surveyStatuses.length) {
      const clusters = [];
      filteredClusters.forEach((cluster: Cluster) => {
        const surveys = cluster.surveys.filter((survey: Survey) => filter.surveyStatuses.includes(survey.status));

        if (surveys.length) {
          clusters.push({
            ...cluster,
            surveys
          });
        }
      });
      filteredClusters = clusters;
    }
    return filteredClusters;
  }
};

export const operator: Module<OperatorState, RootState> = { namespaced: true, state, actions, mutations, getters };
