export default function (value: number): string {
  switch (value) {
    case 1:
      return 'Parcel';
    case 2:
      return 'Farm';
    default:
      return 'Unit';
  }
}
