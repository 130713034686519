

















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ChartsPage extends Vue {
  mongoCharts = process.env.VUE_APP_MONGO_CHARTS;

  get weeklyAreaPublishedAllBrazilUrl(): string {
    return `${this.mongoCharts}/mongodb-charts-ldehe/embed/charts?id=51a05dad-42a0-47f5-914d-22e48e450edb&tenant=92d42070-7f24-4fb7-a6d9-55b35b5e8027&autorefresh=300&theme=light`;
  }
  get weeklyAreaPublishedAllRestUrl(): string {
    return `${this.mongoCharts}/mongodb-charts-ldehe/embed/charts?id=d4473527-ed8f-4e0a-b3f3-ae02d8052c7b&tenant=92d42070-7f24-4fb7-a6d9-55b35b5e8027&theme=light`;
  }

  get weeklyAreaPublishedSowingBrazilUrl(): string {
    return `${this.mongoCharts}/mongodb-charts-ldehe/embed/charts?id=dc92a889-f9f1-47dd-82bc-7ccd2db00f79&tenant=92d42070-7f24-4fb7-a6d9-55b35b5e8027&autorefresh=300&theme=light`;
  }

  get monthlyAreaPublishedAllBrazilUrl(): string {
    return `${this.mongoCharts}/mongodb-charts-ldehe/embed/charts?id=961f73cf-15cb-41f6-8ccc-929b11165a0a&tenant=92d42070-7f24-4fb7-a6d9-55b35b5e8027&autorefresh=300&theme=light`;
  }

  get weeklyAreaPublishedWeedsBrazilUrl(): string {
    return `${this.mongoCharts}/mongodb-charts-ldehe/embed/charts?id=13402742-8c94-4d6c-b856-3ab4bc0437e5&tenant=92d42070-7f24-4fb7-a6d9-55b35b5e8027&autorefresh=300&theme=light`;
  }

  get monthlyAreaPublishedCommercialBrazilUrl(): string {
    return `${this.mongoCharts}/mongodb-charts-ldehe/embed/charts?id=d3050133-b7f5-4424-8470-733b0f3bbac6&tenant=92d42070-7f24-4fb7-a6d9-55b35b5e8027&autorefresh=300&theme=light`;
  }
}
