
















import { Component, Vue, Prop } from 'vue-property-decorator';
import API from '@/services/api';
import { ClusterReportItem } from '@/interfaces/cluster-report';

export interface AnalyticReportItem {
  actualNum: number;
  expectedNum: number;
  type: string;
}

@Component
export default class ClusterReport extends Vue {
  @Prop(String) readonly clusterId: string | undefined;
  private report: AnalyticReportItem[] = [];
  private isLoading = false;
  private isLoaded = false;

  async mounted(): Promise<void> {
    if (this.clusterId) {
      this.isLoading = true;
      const clusterReport = await API.getClusterReport(this.clusterId);
      this.isLoading = false;
      this.isLoaded = true;
      this.report = this.mapToAnalyticReport(clusterReport);
    }
  }

  mapToAnalyticReport(clusterReport: ClusterReportItem[]): AnalyticReportItem[] {
    const analyticStat: Map<string, AnalyticReportItem> = new Map([]);
    clusterReport.forEach((surveyReport) => {
      if (!analyticStat.has(surveyReport.analyticType)) {
        analyticStat.set(surveyReport.analyticType, {
          actualNum: surveyReport.surveyId ? 1 : 0,
          expectedNum: 1,
          type: surveyReport.analyticType
        });
        return;
      }

      const currentStat = analyticStat.get(surveyReport.analyticType);
      analyticStat.set(surveyReport.analyticType, {
        type: currentStat.type,
        actualNum: currentStat.actualNum + (surveyReport.surveyId ? 1 : 0),
        expectedNum: currentStat.expectedNum + 1
      });
    });

    return Array.from(analyticStat.values());
  }
}
