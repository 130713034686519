
















import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { InputEvent } from '@/interfaces/event';

@Component
export default class EditableCell extends Vue {
  @Prop(String) readonly text: string | undefined;
  private editable = false;
  private value: string;

  constructor() {
    super();
    this.value = this.text;
  }

  @Watch('text')
  onTextChanged(newText: string): void {
    this.value = newText;
  }

  handleChange(e: InputEvent): void {
    const value = e.target.value;
    this.value = value;
  }

  @Emit('change')
  check(): string {
    this.editable = false;
    return this.value;
  }

  edit(): void {
    this.editable = true;
  }
}
