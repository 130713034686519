





































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { AligningStatus, PhaseStatus, ClusterPhaseKey, ClusterPhaseType, ClusterStatus } from '@/interfaces/cluster';
import {
  ClientSurveyStatus,
  ClientCluster,
  ClientClusterStatus,
  ClientClusterStatusPhaseType,
  ClientSurvey
} from '@/interfaces/client';
import { ProgressStatusType, ProgressStatus } from '@/enums/progress-status';
import EditableCell from '@/components/EditableCell.vue';
import ColsSvg from '@/assets/icons/cols.svg';
import Review from '@/services/review';
import moment from 'moment';
import { Filter } from '@/interfaces/filter';
import JsonCSV from 'vue-json-csv';
import Socket from '@/services/socket';
import { CheckBoxEvent, MenuEvent } from '@/interfaces/event';
import { TableFilter, TablePagination } from '@/interfaces/table';

import { m2ToHa } from '@/filters/area';
import { filterTableList } from '@/services/utils';

const columns = [{ title: 'Process', width: 375, key: 'process', scopedSlots: { customRender: 'process' } }];

const innerColumns = [
  { title: 'SurveyID', dataIndex: 'surveyId', key: 'surveyId' },
  { title: 'Parcel', dataIndex: 'parcelName', key: 'parcelName', scopedSlots: { customRender: 'parcelName' } },
  { title: 'Analytic', dataIndex: 'analyticType', key: 'analyticType' },
  { title: 'Ha', dataIndex: 'area', key: 'area', scopedSlots: { customRender: 'area' } },
  { title: 'Status', dataIndex: 'status', width: 200, key: 'status', scopedSlots: { customRender: 'status' } },
  {
    title: 'Ortho quality',
    dataIndex: 'orthoQuality',
    key: 'orthoQuality',
    scopedSlots: { customRender: 'orthoQuality' }
  },
  { title: 'Notes', dataIndex: 'clientNotes', key: 'clientNotes' }
];

export interface Column {
  title: string;
  dataIndex?: string;
  key: string;
  scopedSlots?: { customRender?: string };
  sorter?: (a: unknown, b: unknown) => number;
  filters?: { text?: string; value?: string | number }[];
  onFilter?: (value: string, record: unknown) => boolean;
  order?: number;
}

export interface ProcessInfoData {
  key: string;
  time?: number;
  startTime?: string;
  endTime?: string;
}

export interface CsvData extends ClientSurvey {
  clusterId: string;
  farmName: string;
  organizationName: string;
  uploadingEnd: string;
}

@Component({
  components: {
    EditableCell,
    ColsSvg,
    downloadCsv: JsonCSV
  }
})
export default class ClientTable extends Vue {
  tableScrollOpt = { y: window.innerHeight - 230 };
  exportedFileName = `${new Date().getTime()}.csv`;
  tableFilter: TableFilter = {}; // filters chosen on table, uses only for export data what user currently see

  labels = {
    clusterId: 'clusterId',
    area: 'area',
    farmName: 'farm',
    surveyId: 'surveyId',
    parcelName: 'parcelName',
    status: 'status',
    organizationName: 'organizationName',
    uploadingEnd: 'dateUploaded',
    analyticType: 'analyticType',
    clientNotes: 'notes'
  };
  exportingFields = [
    'clusterId',
    'parcelName',
    'surveyId',
    'area',
    'farmName',
    'status',
    'organizationName',
    'uploadingEnd',
    'analyticType',
    'clientNotes'
  ];

  private innerColumns = innerColumns;
  private columns: Column[] = columns;

  aligningStatus = AligningStatus;
  clientSurveyStatus = ClientSurveyStatus;
  clientClusterStatus = ClientClusterStatus;
  clusterStatus = ClusterStatus;

  groupOptions = [
    { label: 'Cluster ID', value: 'clusterId', scopedSlots: { customRender: 'clusterId' }, order: 10 },
    {
      label: 'Client',
      value: 'client',
      scopedSlots: { customRender: 'client' },
      order: 9
    },
    { label: 'Farm', value: 'farmName', order: 8 },
    {
      label: 'Ha',
      value: 'area',
      sorter: (a: ClientCluster, b: ClientCluster): number => a.area - b.area,
      scopedSlots: { customRender: 'area' },
      order: 7
    },
    {
      label: 'Status',
      value: 'status',
      width: 120,
      scopedSlots: { customRender: 'status' },
      filters: [
        { text: 'Uploading', value: ClientClusterStatus.Uploading },
        { text: 'Stitching', value: ClientClusterStatus.Stitching },
        { text: 'Ortho Review', value: ClientClusterStatus.OrthoReview },
        { text: 'Processing', value: ClientClusterStatus.Processing },
        { text: 'Published', value: ClientClusterStatus.Published },
        { text: 'Rejected', value: ClientClusterStatus.Rejected }
      ],
      onFilter: (value: string, record: ClientCluster): boolean => record.status.indexOf(value) === 0,
      order: 6
    },
    { label: 'Notes', value: 'clientNotes', width: 250, order: 5 },
    {
      label: 'Date uploaded',
      value: 'uploading.endTime',
      sorter: (a: ClientCluster, b: ClientCluster): number => {
        return new Date(a.uploading.endTime).getTime() - new Date(b.uploading.endTime).getTime();
      },
      scopedSlots: { customRender: 'dateTime' },
      order: 3
    },
    {
      label: 'Delivery date',
      value: 'deliveredTime',
      sorter: (a: ClientCluster, b: ClientCluster): number => {
        return new Date(a.deliveredTime).getTime() - new Date(b.deliveredTime).getTime();
      },
      scopedSlots: { customRender: 'dateTime' },
      order: 2
    }
  ];
  defaultCheckedList = ['clusterId', 'client', 'farmName', 'area', 'status', 'clientNotes'];
  indeterminate = true;
  checkAll = false;
  checkedList = this.defaultCheckedList;

  get clusters(): ClientCluster[] {
    return this.$store.state.client.filteredClusters;
  }

  get invoiced(): boolean {
    return this.filter.invoiced;
  }

  get filter(): Filter {
    return this.$store.state.client.filter;
  }

  get range(): [moment.Moment?, moment.Moment?] {
    const { from, to } = this.filter;
    if (!from && !to) {
      return [];
    }
    return [moment.utc(from), moment.utc(to)];
  }

  get datePickerFrom() {
    return this.$store.state.fromClientView;
  }

  onSearch(value: string): void {
    this.$store.dispatch('client/filterClusters', { search: value });
  }

  async mounted(): Promise<void> {
    this.loadClusters();
    Socket.client.on('onUpdate', this.onUpdate.bind(this));
    Socket.client.on('onDelete', this.onDelete.bind(this));
  }

  private loadClusters(): void {
    if (this.datePickerFrom) {
      const fromDate = this.datePickerFrom?.utc().format();
      this.$store.dispatch('client/loadClusters', fromDate);
      this.updateColumns();
    }
  }

  public onFromChange(date): void {
    if (date) {
      this.$store.dispatch('setFromClientView', date);
      this.loadClusters();

      if (this.filter.from && this.filter.to) {
        this.onRangeChange([this.datePickerFrom, moment.utc(this.filter.to)]);
      }
    }
  }

  getCurrentStepIndex(cluster: ClientCluster): number {
    const doneIndex = 3;
    const stepsMap = {
      [this.clientClusterStatus.Uploading]: 0,
      [this.clientClusterStatus.Stitching]: 1,
      [this.clientClusterStatus.OrthoReview]: 2,
      [this.clientClusterStatus.Processing]: doneIndex, // Aligning done
      [this.clientClusterStatus.Published]: doneIndex, // Aligning done
      [this.clientClusterStatus.Rejected]: doneIndex // Aligning done
    };

    return stepsMap[cluster.status];
  }

  getCurrentStep(cluster: ClientCluster): ClusterPhaseType {
    const keyPropMap = {
      [ClusterStatus.Uploading]: ClusterPhaseKey.uploading,
      [ClusterStatus.Stitching]: ClusterPhaseKey.stitching,
      [ClusterStatus.Aligning]: ClusterPhaseKey.aligning
    };
    const currentKey = keyPropMap[cluster.lastPhase];
    return cluster[currentKey];
  }

  getCurrentStepName(cluster: ClientCluster): ClientClusterStatusPhaseType {
    const keyPropMap: {
      [key: string]: ClientClusterStatusPhaseType;
    } = {
      [ClusterStatus.Uploading]: ClientClusterStatus.Uploading,
      [ClusterStatus.Stitching]: ClientClusterStatus.Stitching,
      [ClusterStatus.Aligning]: ClientClusterStatus.OrthoReview,
      default: ClientClusterStatus.OrthoReview
    };
    const key = cluster.lastPhase || 'default';
    return keyPropMap[key];
  }

  getCurrentStepElapsedTime(cluster: ClientCluster): number {
    const currentStep = this.getCurrentStep(cluster);

    return currentStep?.elapsedTime;
  }

  getCurrentStepProgress(cluster: ClientCluster): number {
    const currentStep = this.getCurrentStep(cluster);
    return currentStep?.progress;
  }

  getCurrentStepProgressStatus(cluster: ClientCluster): ProgressStatusType {
    const phaseStatusToProgressStatus = {
      [PhaseStatus.InProgress]: ProgressStatus.active,
      [PhaseStatus.Finished]: ProgressStatus.success,
      [PhaseStatus.Failed]: ProgressStatus.exception,
      [PhaseStatus.Cancelled]: ProgressStatus.exception,
      default: ProgressStatus.normal
    };
    const currentStep = this.getCurrentStep(cluster);
    const phaseStatusKey = currentStep.status || 'default'; // PhaseStatus or AligningStatus values
    return phaseStatusToProgressStatus[phaseStatusKey];
  }

  getProcessInfoData(cluster: ClientCluster): ProcessInfoData[] {
    const caclTime = (end: string, start: string): number => {
      const startTime = new Date(start).getTime();
      const endTime = new Date(end).getTime();
      if (endTime < startTime) return undefined;
      return endTime - startTime;
    };
    return [
      {
        key: this.clientClusterStatus.Uploading,
        time: caclTime(cluster.uploading.endTime, cluster.uploading.startTime),
        startTime: cluster.uploading.startTime,
        endTime: cluster.uploading.endTime
      },
      {
        key: this.clientClusterStatus.Stitching,
        time: caclTime(cluster.stitching.endTime, cluster.stitching.startTime),
        startTime: cluster.stitching.startTime,
        endTime: cluster.stitching.endTime
      },
      {
        key: this.clientClusterStatus.OrthoReview,
        time: caclTime(cluster.aligning.endTime, cluster.aligning.startTime),
        startTime: cluster.aligning.startTime,
        endTime: cluster.aligning.endTime
      },
      { key: 'Total' }
    ];
  }

  handleCopy(clusterId: string): void {
    this.$copyText(clusterId); // VueClipboard
  }

  handleProcessMenuClick(clusterId: string, e: MenuEvent): void {
    switch (e.key) {
      case 'Review':
        Review.openAlignmentPreviewMode(clusterId);
        break;

      default:
        break;
    }
  }

  updateColumns(): void {
    const extraCols = this.checkedList.map((checkedItem) => {
      const option = this.groupOptions.find((groupOption) => {
        return groupOption.value === checkedItem;
      });

      return {
        title: option.label,
        dataIndex: option.value,
        key: option.value,
        ...option
      } as Column;
    });

    const sortedExtraCols = extraCols.sort((a: Column, b: Column): number => b.order - a.order);
    this.columns = [
      ...sortedExtraCols.slice(0, 5),
      ...columns.slice(0, 1),
      ...sortedExtraCols.slice(5, sortedExtraCols.length),
      ...columns.slice(1, columns.length)
    ];
    // slice use to keep sorting of columns
  }

  onColsChange(checkedList: string[]): void {
    this.indeterminate = !!checkedList.length && checkedList.length < this.groupOptions.length;
    this.checkAll = checkedList.length === this.groupOptions.length;
    this.updateColumns();
  }

  onColsCheckAllChange(e: CheckBoxEvent): void {
    this.checkedList = e.target.checked ? this.groupOptions.map((opt) => opt.value) : [];
    this.indeterminate = false;
    this.checkAll = e.target.checked;
    this.updateColumns();
  }

  onRangeChange([from, to]: [moment.Moment?, moment.Moment?]): void {
    if (from && to) {
      this.$store.dispatch('client/filterClusters', {
        from: from.utcOffset(0).startOf('day').toISOString(),
        to: to.utcOffset(0).endOf('day').toISOString()
      });
    } else {
      this.$store.dispatch('client/filterClusters', { from: '', to: '' });
    }
  }

  handleInvoicedChange(): void {
    this.$store.dispatch('client/filterClusters', { invoiced: !this.invoiced });
    if (!this.invoiced) {
      this.onRangeChange([]);
    }
  }

  get exportData(): CsvData[] {
    const clusters = filterTableList(this.clusters, this.tableFilter);
    // convert clusters list to surveys with cluster
    const surveys = clusters.reduce((acc, cluster: ClientCluster) => {
      const surveysPerCluster = cluster.surveys.map((survey) => {
        const clusterUploadingEnd = cluster?.uploading?.endTime;
        return {
          clusterId: cluster.clusterId,
          farmName: cluster?.farmName,
          organizationName: cluster?.client?.organizationName,
          uploadingEnd: clusterUploadingEnd ? new Date(cluster?.uploading?.endTime).toISOString().substr(0, 10) : null,
          ...survey,
          area: m2ToHa(survey.area)
        };
      });
      acc.push(...surveysPerCluster);
      return acc;
    }, []);
    return surveys;
  }

  private onUpdate(data): void {
    this.$store.dispatch('client/loadCluster', data);
  }

  private onDelete(clusterId: string): void {
    this.$store.dispatch('client/deleteCluster', clusterId);
  }

  public disabledFromDate(date): boolean {
    return date && date > moment.utc().startOf('day');
  }

  public disabledRangeDate(date): boolean {
    return date && (date < this.datePickerFrom.startOf('day') || date > moment.utc().startOf('day'));
  }

  beforeDestroy(): void {
    Socket.client.off('onDelete');
    Socket.client.off('onUpdate');
  }

  onTableChange(pagination: TablePagination, filters: TableFilter): void {
    // has 3 param as sorter: TableSorter
    this.tableFilter = filters;
  }
}
