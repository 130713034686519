import { Filter } from '@/interfaces/filter';
import { SatAnalyticNameType } from '@/enums/satanalytic';

export enum SatSurveyStatus {
  Processing = 'Processing',
  Published = 'Published'
}

export type SatSurveyStatusType = SatSurveyStatus.Processing | SatSurveyStatus.Published;

export interface SatSurvey {
  key?: number;
  unitId: string;
  name: string;
  source: string[];
  date: string;
  farmName: string;
  season: string;
  organizationName: string;
  analyticType: SatAnalyticNameType;
  status: SatSurveyStatusType;
  progress: number;
  processed: number;
  total: number;
  publishTime: string;
  processingStartTime: string;
  processingEndTime: string;
  processingTime: number;
  failed: number;
  skipped: number;
  area: number;
  notes: string;
  clientNotes: string;
  invoice: boolean;
  unitName?: string;
  parcelCount?: number;
  planet?: boolean;
  processingType?: number;
}

export interface SatSurveyUpdateData {
  analyticType: string;
  notes?: string;
  clientNotes?: string;
  invoice?: boolean;
}

export interface SatSurveyState {
  data: SatSurvey[];
  filteredData: SatSurvey[];
  filter: Filter;
}
