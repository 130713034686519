import { render, staticRenderFns } from "./SatSubscriptions.vue?vue&type=template&id=dc17ffac&scoped=true&"
import script from "./SatSubscriptions.vue?vue&type=script&lang=ts&"
export * from "./SatSubscriptions.vue?vue&type=script&lang=ts&"
import style0 from "./SatSubscriptions.vue?vue&type=style&index=0&id=dc17ffac&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc17ffac",
  null
  
)

export default component.exports