import { ActionTree, Module, MutationTree } from 'vuex';

import { Filter } from '@/interfaces/filter';
import { RootState } from '@/interfaces/root-state';
import API from '@/services/api';
import { AreaType, SatSubscription, SatSubscriptionState } from '@/interfaces/satsubscription';

export const state: SatSubscriptionState = {
  data: [],
  filteredData: [],
  filter: {
    search: ''
  }
};

export const actions: ActionTree<SatSubscriptionState, RootState> = {
  async loadData({ commit, dispatch, state }) {
    dispatch('showGlobalLoader', true, { root: true });
    const subscriptions = await API.getSatSubscriptions();
    let unitIds = subscriptions
      .filter((x) => x.areas[0].type === AreaType.UnitType)
      .map((x) => x.areas[0].id)
      .filter((id, i, self) => self.indexOf(id) === i);
    const farmIds = subscriptions
      .filter((x) => x.areas[0].type === AreaType.FarmType)
      .map((x) => x.areas[0].id)
      .filter((id, i, self) => self.indexOf(id) === i);
    const farms = await Promise.all(
      farmIds.map((x) => {
        return API.getFarm(x);
      })
    );

    unitIds = unitIds.concat(farms.map((x) => x.UnitID)).filter((id, i, self) => self.indexOf(id) === i);
    const units = await Promise.all(
      unitIds.map((x) => {
        return API.getUnit(x);
      })
    );
    const orgIds = units.map((x) => x.OrganizationID).filter((id, i, self) => self.indexOf(id) === i);
    const orgs = await Promise.all(
      orgIds.map((x) => {
        return API.getOrganization(x);
      })
    );
    subscriptions.forEach((x) => {
      switch (x.areas[0].type) {
        case AreaType.FarmType: {
          const farm = farms.find((f) => f.id === x.areas[0].id);
          const unit = units.find((u) => u.id === farm.UnitID);
          const org = orgs.find((o) => o.id === unit.OrganizationID);
          x.farmName = farm.Name;
          x.unitName = unit.Name;
          x.organizationName = org.Name;
          break;
        }
        case AreaType.UnitType: {
          const unit = units.find((u) => u.id === x.areas[0].id);
          const org = orgs.find((o) => o.id === unit.OrganizationID);
          x.unitName = unit.Name;
          x.organizationName = org.Name;
          break;
        }
        case AreaType.ParcelType: {
          // not supported, just a fallback
          x.farmName = `Parcel: ${x.areas[0].id}`;
          x.organizationName = '';
          break;
        }
      }
    });
    commit('setData', subscriptions);
    dispatch('filterData', state.filter);
    dispatch('showGlobalLoader', false, { root: true });
  },

  filterData({ commit, dispatch }, filter: Filter) {
    dispatch('showGlobalLoader', true, { root: true });
    commit('filterData', filter);
    dispatch('showGlobalLoader', false, { root: true });
  }
};

export const mutations: MutationTree<SatSubscriptionState> = {
  setData: (state, data: SatSubscription[]) => {
    state.data = data;
  },

  filterData: (state, filter: Filter) => {
    if (!filter.search) {
      state.filteredData = state.data;
    }
    if (!state.data) {
      return;
    }
    state.filter = filter;
    state.filteredData = state.data.filter((dataItem: SatSubscription) => {
      const { organizationName } = dataItem;
      return organizationName.toLowerCase().includes(filter.search.toLowerCase());
    });
  }
};

export const satsubscriptions: Module<SatSubscriptionState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
