export enum CurrencyType {
  USD = 'USD',
  BRL = 'BRL'
}

export interface CurrencyInfo {
  symbol: string;
  rateToUSD: number;
}

export function getCurrencyInfo(currency: CurrencyType): CurrencyInfo {
  const currencyMap = {
    USD: {
      symbol: '$',
      rateToUSD: 1
    },
    BRL: {
      symbol: 'BRL',
      rateToUSD: 5.4
    }
  };

  const key = currency || CurrencyType.USD;

  return currencyMap[key];
}
