import { ActionTree, Module, MutationTree } from 'vuex';

import { ClientCluster, ClientState, ClientSurveyStatus } from '@/interfaces/client';
import { Filter } from '@/interfaces/filter';
import { RootState } from '@/interfaces/root-state';
import API from '@/services/api';
import { parseSearch, isMatchList, isInDateRange } from '@/store/utils';

export const state: ClientState = {
  clusters: [],
  filteredClusters: [],
  filter: {
    search: '',
    from: '',
    to: '',
    invoiced: false
  }
};

export const actions: ActionTree<ClientState, RootState> = {
  async loadClusters({ commit, dispatch, state, rootState }, fromDate?: string) {
    dispatch('showGlobalLoader', true, { root: true });
    const clusters = await API.getAllClientClusters(rootState.orgNames, fromDate);
    commit('setClusters', clusters);
    dispatch('filterClusters', state.filter);

    dispatch('showGlobalLoader', false, { root: true });
  },
  async loadCluster({ commit, dispatch, state, rootState }, clusterId: string) {
    // don't show the spinner
    // updating can happens many times so user don't want to see spinner all that times
    const cluster = await API.getClientClustersById(clusterId);
    if (rootState.orgNames.length === 0 || rootState.orgNames.some((org) => org === cluster.client.organizationName)) {
      // skip cluster which is not belong to organization to prevent them appearing for client
      commit('updateClusters', cluster);
      dispatch('filterClusters', state.filter);
    }
  },
  deleteCluster({ commit, dispatch, state }, clusterId: string) {
    commit('deleteCluster', clusterId);
    dispatch('filterClusters', state.filter);
  },
  filterClusters({ commit, dispatch }, filter: Filter) {
    dispatch('showGlobalLoader', true, { root: true });
    commit('filterClusters', filter);
    dispatch('showGlobalLoader', false, { root: true });
  }
};

export const mutations: MutationTree<ClientState> = {
  setClusters: (state, clusters: ClientCluster[]) => {
    state.clusters = clusters;
  },
  updateClusters: (state, updatedCluster: ClientCluster) => {
    state.clusters = [
      // immutable update of array save sorting
      ...state.clusters.map((cluster) => {
        if (cluster.clusterId === updatedCluster.clusterId) {
          return { cluster, ...updatedCluster };
        }
        return cluster;
      })
    ];
    const existingCluster = state.clusters.find((cluster) => cluster.clusterId === updatedCluster.clusterId);
    if (!existingCluster) {
      // add new cluster
      state.clusters = [updatedCluster, ...state.clusters];
    }
  },
  deleteCluster: (state, deletedId: string) => {
    state.clusters = [
      // immutable update of array save sorting
      ...state.clusters.filter((cluster) => {
        if (cluster.clusterId === deletedId) {
          return false;
        }
        return true;
      })
    ];
  },
  filterClusters: (state, filter: Filter) => {
    state.filter = { ...state.filter, ...filter }; // replace only changed props, keep previous defined values
    const { search, from, to, invoiced } = state.filter;
    if (!search && !invoiced) {
      state.filteredClusters = state.clusters.map((cluster) => ({ ...cluster }));
    }

    state.filteredClusters = state.clusters
      .filter((cluster) => {
        const {
          clusterId,
          client: { organizationName = '' },
          farmName,
          surveys,
          clientNotes
        } = cluster;

        const isClusterOrSurveyMatch = parseSearch(search).every((searchPart) => {
          const isClusterMatch = isMatchList([clusterId, organizationName, farmName, clientNotes], searchPart);

          const surveyProperties = surveys
            .map(({ surveyId, parcelName, analyticType, clientNotes }) => [
              surveyId,
              parcelName,
              analyticType,
              clientNotes
            ])
            .reduce((acc, current) => {
              return [...acc, ...current];
            }, []); // flat array of values
          const isSurveyMatch = isMatchList(surveyProperties, searchPart);

          return isClusterMatch || isSurveyMatch;
        });

        const isFromToMatch = invoiced ? isInDateRange(from, to, cluster.deliveredTime) : true;

        return isClusterOrSurveyMatch && isFromToMatch;
      })
      .map((cluster) => {
        const isMatchAnyAnalytic = cluster.surveys.some(({ analyticType }) => {
          return parseSearch(search).some((searchPart) => isMatchList([analyticType], searchPart));
        });
        if (!isMatchAnyAnalytic) {
          return { ...cluster };
        }

        // filter by analytic type
        // handle case when search string contains any of analytic type
        const surveys = cluster.surveys.filter(({ analyticType }) => {
          return parseSearch(search).some((searchPart) => isMatchList([analyticType], searchPart));
        });
        return { ...cluster, surveys };
      })
      .map((cluster) => {
        if (!cluster.surveys.length || !invoiced) {
          return { ...cluster };
        }
        // filter by status
        const surveys = cluster.surveys.filter((survey) => {
          return survey.status === ClientSurveyStatus.Rejected || survey.status === ClientSurveyStatus.Published;
        });
        return { ...cluster, surveys };
      });
  }
};

export const client: Module<ClientState, RootState> = { namespaced: true, state, actions, mutations };
