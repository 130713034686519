import { OutputNameType } from '@/enums/output';
import { Survey } from '@/interfaces/survey';

export enum ClusterStatus {
  Uploading = 'Uploading',
  Stitching = 'Stitching',
  ToBeAligned = 'To be Aligned',
  Aligning = 'Aligning',
  Processing = 'Processing',
  WaitingCuration = 'Waiting Curation',
  InCuration = 'In Curation',
  Published = 'Published',
  Rejected = 'Rejected'
}

export type ClusterStatusPhaseType = ClusterStatus.Uploading | ClusterStatus.Stitching | ClusterStatus.Aligning;

export type ClusterStatusType =
  | ClusterStatus.Uploading
  | ClusterStatus.Stitching
  | ClusterStatus.ToBeAligned
  | ClusterStatus.Aligning
  | ClusterStatus.Processing
  | ClusterStatus.WaitingCuration
  | ClusterStatus.InCuration
  | ClusterStatus.Published
  | ClusterStatus.Rejected;

export enum AligningStatus {
  InProgress = 'In Progress',
  Failed = 'Failed',
  Aligned = 'Aligned',
  Accepted = 'Accepted'
}

export type AligningStatusType = AligningStatus.Accepted | AligningStatus.Aligned | AligningStatus.InProgress;

export enum PhaseStatus {
  InProgress = 'In Progress',
  Finished = 'Finished',
  Failed = 'Failed',
  Cancelled = 'Cancelled'
}

export enum ClusterPhaseKey {
  uploading = 'uploading',
  stitching = 'stitching',
  aligning = 'aligning'
}

export interface Uploading {
  progress: number;
  elapsedTime?: number;
  startTime: string;
  endTime?: string;
  status: PhaseStatus;
}

export interface Stitching {
  progress: number;
  elapsedTime?: number;
  startTime: string;
  endTime?: string;
  status: PhaseStatus;
}

export interface Aligning {
  progress: number;
  elapsedTime?: number;
  status: AligningStatusType;
  startTime: string;
  endTime?: string;
}

export type ClusterPhaseType = Uploading | Stitching | Aligning;

export interface Cluster {
  key?: number;
  clusterId: string;
  client: {
    organizationName: string;
    firstName: string;
    lastName: string;
    flightDate: number;
    droneOperator: string;
    droneType: string;
    ortho: boolean;
    uploaderVersion: string;
  };
  farmName: string;
  status: ClusterStatusType;
  area: number;
  notes: string;
  priority: number;
  uploading: Uploading;
  stitching: Stitching;
  aligning: Aligning;
  surveys: Survey[];
  lastPhase: ClusterStatusPhaseType;
  clientNotes: string;
  deliveredTime: string;
  outputs: OutputNameType[];
}

export interface ClusterUpdateData {
  priority?: number;
  notes?: string;
  clientNotes?: string;
}
