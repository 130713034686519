import { message } from 'ant-design-vue';
import Axios from 'axios';

import { SatAnalyticName } from '@/enums/satanalytic';
import { AuthorizeResponse, RefreshSessionResponse } from '@/interfaces/auth';
import { ClientCluster } from '@/interfaces/client';
import {
  ClientInvoice,
  ClientInvoiceQuery,
  ClientNotice,
  ClientNoticesQuery,
  DetailClientInvoice
} from '@/interfaces/client-invoice';
import { Cluster, ClusterUpdateData } from '@/interfaces/cluster';
import { ClusterReportItem } from '@/interfaces/cluster-report';
import { ClusterUpdateResponse } from '@/interfaces/cluster-update-response';
import { Country } from '@/interfaces/country';
import { Farm } from '@/interfaces/farm';
import { Invoice, InvoiceQuery } from '@/interfaces/invoice';
import { ManagementItem, ManagementQuery } from '@/interfaces/management';
import { Organization } from '@/interfaces/organization';
import { SatSubscription } from '@/interfaces/satsubscription';
import { SatSurvey, SatSurveyUpdateData } from '@/interfaces/satsurvey';
import { SurveyUpdateData } from '@/interfaces/survey';
import { SurveyUpdateResponse } from '@/interfaces/survey-update-response';
import { Unit } from '@/interfaces/unit';

class API {
  static _PRODUCT_MANAGER_URL = `${process.env.VUE_APP_PRODUCT_MANAGER}/api`;
  static _DASHBOARD_SERVER = `${process.env.VUE_APP_DASHBOARD_SERVER}/api`;
  static _SATELLITE_SERVER = `${process.env.VUE_APP_SATELLITE_SERVER}`;
  static STORAGE_BASE_URL = 'https://storage.googleapis.com';
  static SAT_NEMATODES_BUCKET = process.env.VUE_APP_SAT_NEMATODES_BUCKET;
  static SAT_SUGAR_RESULTS_BUCKET = process.env.VUE_APP_SAT_SUGAR_RESULTS_BUCKET;
  static DASHBOARD_DEBUG_DEV_BUCKET = process.env.VUE_APP_DASHBOARD_DEBUG_BUCKET;

  static async getNematodesReportCSV(unitId: string, date: string): Promise<string> {
    try {
      const { data } = await Axios.get(`${API.STORAGE_BASE_URL}/${API.SAT_NEMATODES_BUCKET}/${unitId}_${date}.csv`);
      return data;
    } catch (error) {
      API.handleError();
      throw error;
    }
  }

  // save file from api
  static async downloadFloweringRiskReportCSV(unitId: string): Promise<void> {
    try {
      const { data } = await Axios.get(
        `${API.STORAGE_BASE_URL}/${API.DASHBOARD_DEBUG_DEV_BUCKET}/raw_flowering_risk_${unitId}.csv`
      );

      if (data.size === 0) {
        throw new Error('Report is empty');
      }

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = downloadUrl;

      const fileName = `raw_flowering_risk_${unitId}.csv`;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();
      link.remove();
    } catch (error) {
      API.handleError();
      throw error;
    }
  }

  static async getSatSugarReport(unitId: string, date: string, season: string): Promise<string> {
    try {
      const { data } = await Axios.get(
        `${API.STORAGE_BASE_URL}/${API.SAT_SUGAR_RESULTS_BUCKET}/parcel_errors_${unitId}_${date}_${season}.csv?v1`
      );
      return data;
    } catch (error) {
      API.handleError();
      throw error;
    }
  }

  static async getFloweringRiskReport(unitId: string): Promise<string> {
    try {
      const { data } = await Axios.get(
        `${API.STORAGE_BASE_URL}/${API.DASHBOARD_DEBUG_DEV_BUCKET}/raw_flowering_risk_${unitId}.csv`
      );
      return data;
    } catch (error) {
      API.handleError();
      throw error;
    }
  }

  static getNematodesNDVIChartImageUrl(farmId: string, date: string, parcelId: string): string {
    return `${API.STORAGE_BASE_URL}/${API.SAT_NEMATODES_BUCKET}/${farmId}_${date}_${parcelId}_ndvi_timeseries.png`;
  }

  static getAllClusters(fromDate?: string): Promise<Cluster[]> {
    const query = fromDate ? `?from=${fromDate}` : '';
    return Axios.get(`${API._DASHBOARD_SERVER}/clusters${query}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getManagementData(
    query: ManagementQuery = { from: new Date(0).toISOString(), to: new Date().toISOString() }
  ): Promise<ManagementItem[]> {
    return Axios.get(`${API._DASHBOARD_SERVER}/management?from=${query.from}&to=${query.to}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getInvoiceData(
    query: InvoiceQuery = { from: new Date(0).toISOString(), to: new Date().toISOString() }
  ): Promise<Invoice[]> {
    return Axios.get(`${API._DASHBOARD_SERVER}/invoices?from=${query.from}&to=${query.to}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getClientInvoiceSummary(
    query: ClientInvoiceQuery = {
      from: new Date(0).toISOString(),
      to: new Date().toISOString(),
      organizationNames: []
    }
  ): Promise<ClientInvoice[]> {
    const params = new URLSearchParams();

    params.append('from', query.from);
    params.append('to', query.to);

    for (const orgName of query.organizationNames) {
      params.append('organizationNames', orgName);
    }

    return Axios.get(`${API._DASHBOARD_SERVER}/client-invoices`, {
      withCredentials: true,
      params
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getDetailClientInvoice(
    query: ClientInvoiceQuery = {
      from: new Date(0).toISOString(),
      to: new Date().toISOString(),
      organizationNames: []
    }
  ): Promise<DetailClientInvoice[]> {
    const params = new URLSearchParams();

    params.append('from', query.from);
    params.append('to', query.to);

    for (const orgName of query.organizationNames) {
      params.append('organizationNames', orgName);
    }

    return Axios.get(`${API._DASHBOARD_SERVER}/detail-client-invoices`, {
      withCredentials: true,
      params
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getClustersById(clusterId: string): Promise<Cluster> {
    return Axios.get(`${API._DASHBOARD_SERVER}/clusters/${clusterId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static deleteClusterById(clusterId: string): Promise<ClusterUpdateResponse> {
    return Axios.delete(`${API._DASHBOARD_SERVER}/clusters/${clusterId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static publishClusterById(clusterId: string): Promise<ClusterUpdateResponse> {
    return Axios.post(`${API._DASHBOARD_SERVER}/clusters/publish/${clusterId}`, {}, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static rejectClusterById(clusterId: string): Promise<ClusterUpdateResponse> {
    return Axios.post(`${API._DASHBOARD_SERVER}/clusters/reject/${clusterId}`, {}, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static authorize(): Promise<AuthorizeResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/authorize`, { withCredentials: true })
      .then((result) => result.data)
      .catch(() => false);
  }

  static getCountries(): Promise<Country[]> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/countries?valid=true`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getUserOrgs(country: string): Promise<string[]> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/organizations?country=${country}`, { withCredentials: true })
      .then((result) => {
        return result.data.map((d) => {
          return d.Name;
        });
      })
      .catch(API.handleError);
  }

  static updateCluster(clusterId: string, data: ClusterUpdateData): Promise<ClusterUpdateResponse> {
    return Axios.patch(`${API._DASHBOARD_SERVER}/clusters/${clusterId}`, data, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static updateSurvey(surveyId: string, data: SurveyUpdateData): Promise<SurveyUpdateResponse> {
    return Axios.patch(`${API._DASHBOARD_SERVER}/surveys/${surveyId}`, data, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getAllClientClusters(organizations: string[], fromDate: string): Promise<ClientCluster[]> {
    const params = new URLSearchParams();
    for (const org of organizations) {
      params.append('organizationNames', org);
    }

    if (fromDate?.length) {
      params.append('from', fromDate);
    }

    return Axios.get(`${API._DASHBOARD_SERVER}/client-clusters`, {
      withCredentials: true,
      params
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getClientClustersById(clusterId: string): Promise<ClientCluster> {
    return Axios.get(`${API._DASHBOARD_SERVER}/client-clusters/${clusterId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getClusterReport(clusterId: string): Promise<ClusterReportItem[]> {
    return Axios.get(`${API._DASHBOARD_SERVER}/clusters/${clusterId}/report`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }
  static getUnit(id: string): Promise<Unit> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/unit?id=${id}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }
  static getOrganization(id: string): Promise<Organization> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/organization?id=${id}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }
  static getFarm(id: string): Promise<Farm> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/farm?id=${id}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }
  static getClientNotices(
    query: ClientNoticesQuery = {
      from: new Date(0).toISOString(),
      to: new Date().toISOString(),
      organizationNames: []
    }
  ): Promise<ClientNotice[]> {
    const params = new URLSearchParams();

    params.append('from', query.from);
    params.append('to', query.to);

    for (const orgName of query.organizationNames) {
      params.append('organizationNames', orgName);
    }

    return Axios.get(`${API._DASHBOARD_SERVER}/client-notices`, {
      withCredentials: true,
      params
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getSatSurveys(fromDate?: string): Promise<SatSurvey[]> {
    const query = fromDate ? `?from=${fromDate}` : '';
    return Axios.get(`${API._DASHBOARD_SERVER}/sat-surveys${query}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static updateSatSurvey(entityId: string, date: string, data: SatSurveyUpdateData): Promise<SurveyUpdateResponse> {
    return Axios.patch(`${API._DASHBOARD_SERVER}/sat-surveys/${entityId}/${date}`, data, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static async getSatSubscriptions(): Promise<SatSubscription[]> {
    return await Axios.get(`${API._SATELLITE_SERVER}/subscriptions`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/refresh-session`, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static handleError(): void {
    message.error('something went wrong');
  }
}

export default API;
