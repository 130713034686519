import { CurrencyType, getCurrencyInfo } from '@/enums/currency';

export function currency(value: number, currencyType = CurrencyType.USD): string {
  if (value || value === 0) {
    const currencyInfo = getCurrencyInfo(currencyType);
    const totalValue = Math.round(value * currencyInfo.rateToUSD * 100) / 100;

    return `${currencyInfo.symbol}${totalValue}`;
  }
}

export function currencyValue(value: number, currencyType = CurrencyType.USD): number {
  if (value || value === 0) {
    const currencyInfo = getCurrencyInfo(currencyType);
    const totalValue = Math.round(value * currencyInfo.rateToUSD * 100) / 100;

    return totalValue;
  }
}
