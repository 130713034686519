
























import { Component, Vue } from 'vue-property-decorator';
import { SatSurveyStatus, SatSurvey } from '@/interfaces/satsurvey';
import API from '@/services/api';
import EditableCell from '@/components/EditableCell.vue';
import { SatSubscription } from '@/interfaces/satsubscription';
const columnsSatSubscriptions = [
  {
    title: 'Organization',
    dataIndex: 'organizationName',
    key: 'organizationName',
    width: 150
  },
  {
    title: 'Product',
    dataIndex: 'detail.analytics',
    key: 'detail.analytics',
    width: 100,
    filters: [
      { text: 'weeds', value: 'weeds' },
      { text: 'nematodes', value: 'nematodes' },
      { text: 'sugar', value: 'sugar' }
    ],
    onFilter: (value, record) => record.detail.analytics == value
  },
  {
    title: 'Unit',
    dataIndex: 'unitName',
    key: 'unitName',
    width: 100
  },
  {
    title: 'Farm',
    dataIndex: 'farmName',
    key: 'farmName',
    width: 100
  },
  {
    title: 'Launched Date',
    dataIndex: 'startDate',
    key: 'startDate',
    scopedSlots: { customRender: 'launchDate' },
    width: 100
  },
  {
    title: 'Last Run Date',
    dataIndex: 'detail.date',
    key: 'detail.date',
    width: 100
  },
  {
    title: 'End Date',
    dataIndex: 'detail.endDate',
    key: 'detail.endDate',
    width: 100
  }
];

@Component({ components: { EditableCell } })
export default class SatSubscriptionsTable extends Vue {
  private columnsSatSubscriptions = columnsSatSubscriptions;
  satSurveyStatus = SatSurveyStatus;
  get dataSource(): SatSubscription[] {
    return this.$store.state.satsubscriptions.filteredData;
  }

  mounted(): void {
    this.$store.dispatch('satsubscriptions/loadData');
  }

  onSearch(value: string): void {
    this.$store.dispatch('satsubscriptions/filterData', { search: value });
  }

  get tableScrollOpt(): { y: number } {
    const headerHeight = 180;
    const footerHeight = 155;
    return { y: window.innerHeight - headerHeight - footerHeight };
  }
}
