class Review {
  static _ALIGNMENT_TOOL = process.env.VUE_APP_ALIGNMENT_TOOL;
  static _SOWING_CURATION_TOOL = process.env.VUE_APP_SOWING_CURATION_TOOL;
  static _WEEDS_CURATION_TOOL = process.env.VUE_APP_WEEDS_CURATION_TOOL;
  static _ORTHO_BUCKET = process.env.VUE_APP_ORTHO_BUCKET;
  static _ORTHO_URL = process.env.VUE_APP_ORTHO_URL;

  static openAlignment(clusterId: string): void {
    window.open(`${Review._ALIGNMENT_TOOL}/${clusterId}`);
  }

  static openAlignmentPreviewMode(clusterId: string): void {
    window.open(`${Review._ALIGNMENT_TOOL}/preview/${clusterId}`);
  }

  static openStitchingReport(clusterId: string): void {
    window.open(`${Review._ORTHO_URL}/${clusterId}/html/index.html`);
  }

  static openSowingCuration(surveyId: string): void {
    window.open(`${Review._SOWING_CURATION_TOOL}/?surveyId=${surveyId}`);
  }

  static openWeedsCuration(surveyId: string): void {
    window.open(`${Review._WEEDS_CURATION_TOOL}/?surveyId=${surveyId}`);
  }

  static downloadOrtho(clusterId: string): void {
    window.open(`${Review._ORTHO_BUCKET}/${clusterId}.tif`);
  }
}

export default Review;
