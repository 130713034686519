







import { Component, Vue } from 'vue-property-decorator';
import Socket from '@/services/socket';
import Header from '@/components/Header.vue';
import API from '@/services/api';

@Component({
  components: { Header }
})
export default class Dashboard extends Vue {
  private refreshSessionInterval = 600000; // millisecond, 10 min

  mounted(): void {
    Socket.client.on('connect', this.onConnect.bind(this));

    setInterval(() => {
      API.refreshSession();
    }, this.refreshSessionInterval);
  }

  private onConnect(): void {
    // TODO: load clusters list
    // console.log('connect ' + Socket.client.id);
  }
}
