export enum SatAnalyticName {
  nematodes = 'nematodes',
  weeds = 'weeds',
  ndvi = 'ndvi',
  flowering = 'flowering',
  sugar = 'sugar',
  landUse = 'land-use',
  deforestation = 'deforestation',
  cropClassification = 'crop-classification'
}
export type SatAnalyticNameType =
  | SatAnalyticName.nematodes
  | SatAnalyticName.weeds
  | SatAnalyticName.ndvi
  | SatAnalyticName.flowering
  | SatAnalyticName.sugar
  | SatAnalyticName.landUse
  | SatAnalyticName.deforestation
  | SatAnalyticName.cropClassification;
