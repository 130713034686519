import { TableFilter } from '@/interfaces/table';

export function filterTableList<T>(list: Array<T>, filter: TableFilter): Array<T> {
  return list.filter((item) => isItemMatch(item, filter));
}

export function isItemMatch<T>(item: T, filter: TableFilter): boolean {
  return Object.keys(filter).every((filterProperty: string) => {
    return filter[filterProperty].includes(item[filterProperty].toString());
  });
}
